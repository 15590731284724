import { Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import colorConfigs from "../../configs/colorConfigs";
import { RouteType } from "../../routes/config";
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import SidebarItem from "./SidebarItem";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useLocation } from "react-router-dom";

type Props = {
  item: RouteType;
  open?: boolean;
};

const SidebarItemCollapse = ({ item, open = false }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const { appState } = useSelector((state: RootState) => state.appState);
  const clientType = process.env.REACT_APP_CLIENT_TYPE || "default";
  const [colors, setColors] = useState(colorConfigs.default);
  const location = useLocation();

  useEffect(()=> {
    setColors(colorConfigs.default);
  }, [])

  useEffect(() => {
    if (appState?.includes(item.state)) {
      setIsOpen(true);
    }
  }, [appState, item]);

  return (
    item.sidebarProps ? (
      <>
        <ListItem key={item.sidebarProps.displayText} disablePadding sx={{ display: 'block' }}>
          <ListItemButton
            onClick={() => {setIsOpen(!isOpen);}}
            sx={{
              "&: hover": {
                backgroundColor: colors.sidebar.hoverBg,
                color: colors.sidebar.hoverColor,
              },
              color: location.pathname?.includes(item.path) ? colors.sidebar.activeText : colors.sidebar.color,
              backgroundColor: !!location.pathname?.includes(item.path) ? colors.sidebar.activeBg : "unset",
              paddingY: "12px",
              paddingX: "15px",
              margin: "10px 15px",
              borderRadius: "10px",
              justifyContent: open ? 'initial' : 'center'
            }}
          >
            <ListItemIcon sx={{
              color: location.pathname?.includes(item.path) ? colors.sidebar.iconColor : colors.sidebar.iconColor,
              minWidth: 0,
              mr: open ? 3 : 'auto',
              justifyContent: 'center',
            }}>
              {item.sidebarProps.icon && item.sidebarProps.icon}
            </ListItemIcon>
            {!!open && (
              <ListItemText 
                disableTypography 
                primary={
                  <Typography sx={{ opacity: 1 }} >
                    {item.sidebarProps.displayText}
                  </Typography>
                } 
              />
            )}
           
            {(isOpen && !!open) ? <ExpandLessOutlinedIcon fontSize="inherit"/> : !!open && <ExpandMoreOutlinedIcon fontSize="inherit"/>}
          </ListItemButton>
        </ListItem>
        <Collapse in={isOpen} timeout="auto">
          <List>
            {item.child?.map((route, index) => (
              route.sidebarProps ? (
                route.child ? (
                  <SidebarItemCollapse item={route} key={index} open={open}/>
                ) : (
                  <SidebarItem item={route} key={index} open={open} isSub={true}/>
                )
              ) : null
            ))}
          </List>
        </Collapse>
      </>
    ) : null
  );
};

export default SidebarItemCollapse;