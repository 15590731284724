import { Auth } from "types/Auth";
import { StorageKeysType } from "types/Others";
import secureLocalStorage from  "react-secure-storage";

export const storageKey: StorageKeysType = {
  user: "user@chatApp",
  token: "token@chatApp",
  clientPromptId: "clientPromptId@chatApp",
  oldToken: "oldToken@chatApp",
  oldUser: "oldUser@chatApp",
  subscription: 'sub@chatApp'
}

export function setUser(data: Auth.UserProfile){
  return secureLocalStorage.setItem(storageKey.user, data);
}

export function getUser(){
  const user: any = secureLocalStorage.getItem(storageKey.user);
  return user;
}

export function setToken(data: string){
  return secureLocalStorage.setItem(storageKey.token, data);
}

export function getToken() {
  const token = secureLocalStorage.getItem(storageKey.token);
  return token?.toString();
}

export function setToStorage(key: string, data: any){
  return secureLocalStorage.setItem(key, data);
}

export function getFromStore(key: string){
  return secureLocalStorage.getItem(key);
}

export function setClientPromptId(data: string){
  return secureLocalStorage.setItem(storageKey.clientPromptId, data);
}

export function getClientPromptId(){
  return secureLocalStorage.getItem(storageKey.clientPromptId);
}

export function setOldToken(data: string){
  return secureLocalStorage.setItem(storageKey.oldToken, data);
}

export function getOldToken(){
  return secureLocalStorage.getItem(storageKey.oldToken);
}

export function setOldUser(data: Auth.UserProfile){
  return secureLocalStorage.setItem(storageKey.oldUser, data);
}

export function getOldUser(){
  return secureLocalStorage.getItem(storageKey.oldUser);
}

export function clearAll(){
  secureLocalStorage.clear();
}

export function clearStoreById(key: string){
  secureLocalStorage.removeItem(key);
}