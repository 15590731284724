import React, { useEffect, useState, useRef } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";

type dataParameterProps = {
  onDragEnd: (result: any) => void;
  dataParameterList: any[];
  getDataParameter: (val: any[]) => void;
  handleInputTypeChange: (val: any[]) => void;
  deleteRows: (event: any, index: number) => void;
  addNewRows: (event: any) => void;
  handleValidatonChange: (event: any, index: number) => void;
  handleCustomValidationChange: (
    index: number,
    event: any,
    id: number,
    val: string
  ) => void;
  deleteCustomValidationRows: (index: number, event: any, id: number) => void;
  addCustomValidationNewRows: (index: number, event: any, id: number) => void;
  inputTypeList: any[];
  validationTypeList: any[];
  customValidationParameterList: any[];
  isShowValidationOption: (
    index: number,
    slug: string,
    val: boolean
  ) => boolean;
};

const DataParameter = ({
  onDragEnd = () => null,
  dataParameterList,
  getDataParameter = () => null,
  handleInputTypeChange = () => null,
  deleteRows = () => null,
  addNewRows = () => null,
  handleValidatonChange = () => null,
  handleCustomValidationChange = () => null,
  deleteCustomValidationRows = () => null,
  addCustomValidationNewRows = () => null,
  inputTypeList,
  validationTypeList,
  customValidationParameterList,
  isShowValidationOption = () => null,
}: dataParameterProps) => {
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);
  const [justDragged, setJustDragged] = useState<boolean>(false);
  const autoFocusRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (justDragged && focusedIndex !== null && autoFocusRef.current) {
      autoFocusRef.current.focus();
      setJustDragged(false); 
    }
  }, [focusedIndex, justDragged]);

  const getListStyle = (isDraggingOver: boolean) => ({
    background: "#fff",
    padding: 0,
    width: "100%",
  });

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    userSelect: "none",
    paddingTop: 10,
    paddingBottom: 12,
    paddingLeft: 15,
    paddingRight: 15,
    marginBottom: 10,
    background: "#f2f2f2",
    ...draggableStyle,
  });

  const handleParameterType = (event: any, i: number) => {
    let { value } = event.target;
    const tempDataParameter = structuredClone(dataParameterList);

    tempDataParameter[i].parameterType = value || "";
    if (!value) {
      tempDataParameter[i].isOptionsExist = false;
    }

    tempDataParameter[i].value = "";
    const minValue = tempDataParameter[i]?.minValue;
    const maxValue = tempDataParameter[i]?.maxValue;
    const optionValue = tempDataParameter[i]?.value;
    inputTypeList.map((el) => {
      if (el.slug == value) {
        tempDataParameter[i].isOptionsExist = el.isUsedWithOptions;
      }
    });
    if (minValue == "" || maxValue == "" || optionValue == "") {
      tempDataParameter[i].rangePickerMinMsg = "Enter min value";
      tempDataParameter[i].rangePickerMaxMsg = "Enter max value";
      tempDataParameter[i].optionMsg = "Enter options";
    }
    handleInputTypeChange(tempDataParameter);
  };

  const getDataParameterFormValue = (event: any, i: number) => {
    let { name, value } = event.target;
    const tempDataParameter = structuredClone(dataParameterList);
    tempDataParameter[i][name] = value;
    let minValue = tempDataParameter[i].minValue;
    let maxValue = tempDataParameter[i].maxValue;
    handleRangePickerValidation(tempDataParameter, i);

    if (minValue == "" && maxValue == "") {
      tempDataParameter[i].rangePickerMinMsg = "";
      tempDataParameter[i].rangePickerMaxMsg = "";
    }

    getDataParameter(tempDataParameter);
  };

  const handleRangePickerValidation = (tempDataParameter: any[], index: number) => {
    const minValue = tempDataParameter[index]?.minValue;
    const maxValue = tempDataParameter[index]?.maxValue;
    const parameterType = tempDataParameter[index]?.parameterType;
    const optionValue = tempDataParameter[index]?.value;

    if (optionValue != "") {
      tempDataParameter[index].optionMsg = "";
    } else {
      tempDataParameter[index].optionMsg = "Enter options";
    }

    if (parameterType == "range-picker") {
      const negativeCheckRegex = /^-?\d*\.?\d{0,2}$/;
      const minResult = negativeCheckRegex.test(minValue);
      const maxResult = negativeCheckRegex.test(maxValue);
      if (minResult) {
        tempDataParameter[index].rangePickerMinMsg = "";
        if (maxResult) {
          tempDataParameter[index].rangePickerMaxMsg = "";
          if (parseInt(minValue) > parseInt(maxValue)) {
            tempDataParameter[index].rangePickerMinMsg =
              "Min value should be smaller than max value";
          } else if (parseInt(minValue) == parseInt(maxValue)) {
            tempDataParameter[index].rangePickerMinMsg =
              " Min value should be not same as max value";
          } else {
            tempDataParameter[index].rangePickerMinMsg = "";
          }
        } else {
          tempDataParameter[index].rangePickerMaxMsg = "Enter valid max value";
        }
      } else {
        tempDataParameter[index].rangePickerMinMsg = "Enter valid min value";
      }
    }
    return tempDataParameter;
  };

  const handleBeforeDragStart = () => {
    if (autoFocusRef.current) {
      autoFocusRef.current.blur();
    }
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    setFocusedIndex(result.destination.index);
    setJustDragged(true);
    onDragEnd(result);
  };

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd} onBeforeDragStart={handleBeforeDragStart}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {dataParameterList?.map((item, index) => (
                <Draggable
                  key={item.key}
                  draggableId={item?.key || `keyId${index}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <div key={index} className="row">
                        <div className="col-4">
                          <label>Field Name</label>
                          <input
                            type="text"
                            ref={index === focusedIndex ? autoFocusRef : null}
                            className="form-control p-2"
                            placeholder="Enter Key"
                            name="id"
                            value={item?.id || ""}
                            onChange={(e) =>
                              getDataParameterFormValue(e, index)
                            }
                          />
                        </div>
                        <div className="col-3">
                          <label>Input Type</label>
                          <select
                            disabled={item.id == ""}
                            name="parameterType"
                            value={item?.parameterType || ""}
                            className="form-select p-2"
                            onChange={(e) => handleParameterType(e, index)}
                          >
                            <option value="">
                              {!!inputTypeList?.length
                                ? "Select input type"
                                : "No data found"}
                            </option>
                            {!!inputTypeList &&
                              inputTypeList?.map((it, i) => {
                                return (
                                  <option
                                    key={i + "" + it?.title}
                                    value={it?.slug || ""}
                                  >
                                    {it?.title}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        {!!item?.isOptionsExist &&
                          item?.parameterType !== "range-picker" && (
                            <div className="col-4">
                              <label className="textNoBreak">
                                Options (Comma separated if available)
                              </label>
                              <input
                                type="text"
                                className="form-control p-2"
                                placeholder="Enter value"
                                name="value"
                                value={item?.value || ""}
                                onChange={(e) =>
                                  getDataParameterFormValue(e, index)
                                }
                              />
                              <span className="rangePickerErrText">
                                {item?.optionMsg}
                              </span>
                            </div>
                          )}

                        {item?.parameterType == "range-picker" && (
                          <div className="col-4">
                            <div className="row">
                              <div className="col-6">
                                <label className="textNoBreak">Min.Value</label>
                                <input
                                  type="text"
                                  className="form-control p-2"
                                  placeholder="Enter value"
                                  name="minValue"
                                  value={item?.minValue || ""}
                                  onChange={(e) =>
                                    getDataParameterFormValue(e, index)
                                  }
                                />
                                <span className="rangePickerErrText">
                                  {item?.rangePickerMinMsg}
                                </span>
                              </div>

                              <div className="col-6">
                                <label className="textNoBreak">Max.Value</label>
                                <input
                                  type="text"
                                  className="form-control p-2"
                                  placeholder="Enter value"
                                  name="maxValue"
                                  value={item?.maxValue || ""}
                                  onChange={(e) =>
                                    getDataParameterFormValue(e, index)
                                  }
                                />
                                <span className="rangePickerErrText">
                                  {item?.rangePickerMaxMsg}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                        
                         {/* <div className="col-1">
                                    <label>Mandatory</label>
                                    <div>
                                      <Checkbox
                                        style={{
                                          transform: "scale(1.8)",
                                        }}
                                        checked={item.isMandatory}
                                        name="isMandatory"
                                        value={item?.isMandatory || ""}
                                        onChange={(e) => getMandatory(e, index)}
                                      />
                                    </div>
                                  </div> */}
                        <div className="col-1 d-flex justify-content-between gap-1 mt-4 align-items-center">
                          {index != 0 && (
                            <div
                              onClick={(e) => deleteRows(e, index)}
                              className="cursor-pointer actionBtnMargin promptIcon"
                            >
                              <DeleteOutlineIcon />
                            </div>
                          )}
                          {dataParameterList[index].id &&
                            index == dataParameterList.length - 1 && (
                              <div
                                onClick={addNewRows}
                                className="cursor-pointer promptIcon"
                              >
                                <AddIcon />
                              </div>
                            )}
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-3">
                          <label>Validation Type</label>
                          <select
                            disabled={item.id == ""}
                            className="form-select p-2"
                            name="validation"
                            value={item?.validation || ""}
                            onChange={(e) => handleValidatonChange(e, index)}
                          >
                            <option value="">
                              {validationTypeList?.length
                                ? "Select validations"
                                : "No data found"}
                            </option>
                            {!!validationTypeList &&
                              validationTypeList?.map((item, i) => (
                                <option
                                  key={i + "" + item?.title}
                                  value={item?.slug || ""}
                                >
                                  {item?.title}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      {(dataParameterList[index]?.validation ==
                        "custom-validation" ||
                        dataParameterList[index]?.validation == "date") &&
                        dataParameterList[index]?.customValidation &&
                        dataParameterList[index]?.customValidation?.map(
                          (item, idx) => (
                            <div
                              className="row customValidationRow mt-2"
                              key={`customValidation${idx}`}
                            >
                              <div className="col-4">
                                <label>Validation Parameter</label>
                                <select
                                  name="validationOperation"
                                  value={item.validationOperation || ""}
                                  className="form-select"
                                  onChange={(event) =>
                                    handleCustomValidationChange(
                                      index,
                                      event,
                                      idx,
                                      "operation"
                                    )
                                  }
                                >
                                  <option value="">
                                    {customValidationParameterList?.length
                                      ? "Select validation parameter"
                                      : "No data found"}
                                  </option>
                                  {!!customValidationParameterList &&
                                    customValidationParameterList.map(
                                      (item, i) => (
                                        <option
                                          key={i + "" + item?.title}
                                          value={item?.slug || ""}
                                          disabled={isShowValidationOption(
                                            index,
                                            item?.slug,
                                            item?.acceptMultipleValidation
                                          )}
                                        >
                                          {item?.title}
                                        </option>
                                      )
                                    )}
                                </select>
                              </div>
                              <div className="col-3">
                                <label>Comparison Type</label>
                                <select
                                  name="validationOperator"
                                  value={item?.validationOperator || ""}
                                  className="form-select"
                                  onChange={(event) =>
                                    handleCustomValidationChange(
                                      index,
                                      event,
                                      idx,
                                      ""
                                    )
                                  }
                                >
                                  <option value="">
                                    {dataParameterList[index]?.customValidation[
                                      idx
                                    ]?.dataJson?.length
                                      ? "Select comparison type"
                                      : "No data found"}
                                  </option>
                                  {!!dataParameterList[index].customValidation[
                                    idx
                                  ]?.dataJson &&
                                    dataParameterList[index].customValidation[
                                      idx
                                    ]?.dataJson?.map((item, i) => (
                                      <option
                                        key={i + "" + item?.title}
                                        value={item?.slug || ""}
                                      >
                                        {item?.title}
                                      </option>
                                    ))}
                                </select>
                              </div>
                              {dataParameterList[index].customValidation[idx]
                                ?.validationOperation != "date-format" && (
                                <div className="col-4">
                                  <label>Validation Value</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter validation value"
                                    name="validationValue"
                                    value={item?.validationValue || ""}
                                    onChange={(event) =>
                                      handleCustomValidationChange(
                                        index,
                                        event,
                                        idx,
                                        ""
                                      )
                                    }
                                  />
                                </div>
                              )}

                              {dataParameterList[index].customValidation[idx]
                                ?.validationOperation == "date-format" && (
                                <div className="col-4">
                                  <label>Date Format</label>
                                  <select
                                    name="validationValue"
                                    className="form-select"
                                    value={item.validationValue || ""}
                                    onChange={(event) =>
                                      handleCustomValidationChange(
                                        index,
                                        event,
                                        idx,
                                        "operation"
                                      )
                                    }
                                  >
                                    <option value="">
                                      {!!dataParameterList[index]
                                        ?.customValidation[idx]?.dataJson &&
                                      dataParameterList[index].customValidation[
                                        idx
                                      ]?.dateFormat.length > 0
                                        ? "Select date format"
                                        : "No data found"}
                                    </option>
                                    {!!dataParameterList[index]
                                      ?.customValidation[idx]?.dataJson &&
                                      dataParameterList[index].customValidation[
                                        idx
                                      ]?.dateFormat?.map((item, idd) => (
                                        <option
                                          key={idd + "" + item?.title}
                                          value={item?.slug || ""}
                                        >
                                          {item?.title}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              )}

                              <div className="col-1 d-flex justify-content-around gap-1 mt-3 align-items-center">
                                {idx ==
                                  dataParameterList[index]?.customValidation
                                    ?.length -
                                    1 && (
                                  <div
                                    onClick={(e) =>
                                      deleteCustomValidationRows(index, e, idx)
                                    }
                                    className="cursor-pointer dataParameterIcon"
                                  >
                                    <DeleteOutlineIcon />
                                  </div>
                                )}
                                {idx ==
                                  dataParameterList[index]?.customValidation
                                    ?.length -
                                    1 && (
                                  <div
                                    onClick={(e) =>
                                      addCustomValidationNewRows(index, e, idx)
                                    }
                                    className="cursor-pointer dataParameterIcon"
                                  >
                                    <AddIcon />
                                  </div>
                                )}
                              </div>
                            </div>
                          )
                        )}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default DataParameter;
