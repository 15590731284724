import React, { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Error } from "components/auth/Error";
import "./styles.css";
import { Projects } from "types/Projects";
import { useForm } from "react-hook-form";
import { useAppDispatch, useTypedSelector } from "redux/store";
import {
  getProjectsFromStore,
  resetLoader,
  selectCurrentProjectId,
  selectProjects,
  setCurrentProjectId,
} from "redux/features/projectSlice";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  getChatbotFromStore,
  resetChatbotDetails,
  selectChatbotDetails,
  selectChatbots,
  selectQuotaDetails,
  updateChatbotHeading,
} from "redux/features/chatbotSlice";
import { ChatbotList } from "types/Chatbot";
import { chatbotService } from "services";
import { Input } from "components/auth/Input";
import { toastError, toastSuccess, toastWarning } from "helpers/toastHelper";
import DeleteConfirmation from "components/deleteConfirmation";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import { useIsStaff } from "hooks/useAuth";
import { AlertConfirmation } from "components";
import { Box, LinearProgress, Modal, Typography } from "@mui/material";
import assets from "assets";
let pdfIcon = require("../../../src/assets/images/pdf.jpg");
let linkIcon = require("../../../src/assets/images/link.jpg");
let txtIcon = require("../../../src/assets/images/text.png");
let faqIcon = require("../../../src/assets/images/FAQ.png");

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const inputsConfig = {
  chatbotName: {
    name: "name",
    placeholder: "Enter chatbot name",
    className: "form-control",
    type: "text",
    required: "Enter chatbot name",
  },
};

type Props = {};

const Chatbots = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openDropdown = Boolean(anchorEl);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [promptData, setPromptData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const chatbots: Array<ChatbotList> = useTypedSelector(selectChatbots);
  const projects: Array<Projects> = useTypedSelector(selectProjects);
  const currentProjectId: any = useTypedSelector(selectCurrentProjectId);
  const chatbotDetail: ChatbotList = useTypedSelector(selectChatbotDetails);
  const quotaDetails = useTypedSelector(selectQuotaDetails);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [clientPrompt, setClientPrompt] = useState(null);
  const [clientPromptId, setClientPromptId] = useState();
  const [isChatbotDeleting, setIsChatbotDeleting] = useState(false);
  const [isCloneModalOpen, setIsCloneModalOpen] = useState(false)
  const [isCloneProcessingModalOpen, setIsCloneProcessingModalOpen] = useState(false)
  const [cloningItems, setCloningItems] = useState({"General Setting": 10, "Theme Setting": 30, "Actions": 15, "Integrations": 70, "Content Store": 20, "Q&A": 28})
  const isStaffAccess = useIsStaff();
  
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm();

  useEffect(() => {
    getProjects();
    getPrompts();
  }, []);

  useEffect(() => {
    if (!!currentProjectId) getChatbotHandler(currentProjectId);
  }, [currentProjectId]);

  useEffect(() => {
    if (projects["projects"]?.length > 0 && !currentProjectId) {
      handleProjectChange(projects["projects"][0]?.clientProjectId);
    }
  }, [projects["projects"], currentProjectId]);

  useEffect(() => {
    if (projects["isLoaded"] && !projects["projects"]?.length) {
      toastWarning("Please create project first!");
      dispatch(resetLoader(false));
    }
  }, [projects["projects"]]);

  useEffect(() => {
    if(isCloneProcessingModalOpen) {
      const intervalId = setInterval(() => {
        setCloningItems((prevState) => {
          const newValues = { ...prevState }
          let isComplete = true
          for (const key in prevState) {
            if (prevState[key] < 100) {
              newValues[key] = prevState[key] + 1
              isComplete = false
            }
          }
    
          if (isComplete) {
            toastSuccess("Chatbot has been cloned successfully")
            setIsCloneProcessingModalOpen(false)
            if (!!currentProjectId) getChatbotHandler(currentProjectId)
            clearInterval(intervalId)
          }
          return newValues
        })
      }, 100)
      return () => clearInterval(intervalId)
    }
  
  }, [isCloneProcessingModalOpen])

  const goToDetailPage = (data) => {
    dispatch(resetChatbotDetails(true));
    dispatch(updateChatbotHeading({data: data?.name}));
    navigate("/chatbots/details", { state: { data } });
  };

  const getProjects = async () => {
    if (projects["projects"]?.length === 0) {
      dispatch(getProjectsFromStore({}));
    }
  };

  const getChatbotHandler = (clientId?) => {
    let payload = {};
    if (!!clientId) {
      payload["filter"] = {
        clientProjectId: [Number(clientId)],
      };
    }
    dispatch(getChatbotFromStore(payload));
  };

  const handleProjectChange = (value: number | string) => {
    dispatch(setCurrentProjectId(value));
  };

  const dropdownHandleClick = (
    event: React.MouseEvent<HTMLElement>,
    chatbotDetail
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setClientPrompt(chatbotDetail);
    setClientPromptId(chatbotDetail.clientPromptId);
  };

  const dropdownHandleClose = (event, value) => {
    event.stopPropagation();
    setAnchorEl(null);
    setIsEdit(value);
  };

  const getPrompts = async () => {
    const response = await chatbotService.getPrompts();
    setPromptData(response?.data);
  };

  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);
      let successMsg;
      let response = null;

      if (!isEdit) {
        response = await chatbotService.createChatbot({
          promptId: Number(data.promptId),
          clientProjectId: Number(currentProjectId),
          name: data?.name || "",
          themeConfig: {
            headerColor:
              "linear-gradient(135deg, rgb(42, 39, 218) 0%, rgb(0, 204, 255) 100%)",
            bgColor: "#ffffff",
            botMsgBgColor: "#f0f2f7",
            userMsgBgColor:
              "linear-gradient(135deg, rgb(42, 39, 218) 0%, rgb(0, 204, 255) 100%)",
            botMsgFontColor: "#000",
            userMsgFontColor: "#fff",
          },
        });
      } else {
        response = await chatbotService.updateClientPrompt({
          clientPromptId: clientPrompt.clientPromptId,
          promptId: Number(data.promptId),
          clientId: clientPrompt.clientId,
          name: data?.name || "",
        });
      }
      if (!!response && response?.data?.success) {
        successMsg = response?.data?.message;
        toastSuccess(successMsg);
        getChatbotHandler(currentProjectId);
        handleDilogClose();
      }
    } catch (e) {
      setIsSubmitting(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClickOpen = (e, value) => {
    if (!!projects["projects"]?.length) {
      setValue("name", "");
      e.stopPropagation();
      setIsEdit(value);
      setOpen(true);
      clearErrors();
      // navigate("/chatbots/choose-template")
    } else {
      toastWarning("Create a project first")
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDilogClose = () => {
    setOpen(false);
  };

  const dropdownHandleEdit = (event, value) => {
    event.stopPropagation();
    setAnchorEl(null);
    setIsEdit(value);
    setOpen(true);
    setValue("promptId", clientPrompt.promptId);
    setValue("name", clientPrompt.name);
    clearErrors();
  };

  const dropdownHandleClone = (event) => {
    event.stopPropagation()
    setAnchorEl(null)
    setIsCloneModalOpen(true)
  }

  const dropdownHandleDelete = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
    setIsAlertOpen(true);
  };

  const handleDelete = async () => {
    try {
      setIsChatbotDeleting(true);
      const response = await chatbotService.deleteChatbot({
        clientPromptId: clientPromptId,
      });

      if (response?.data?.success) {
        setIsChatbotDeleting(false);
        setIsAlertOpen(false);
        toastSuccess(response?.data?.message);
        getChatbotHandler(currentProjectId);
      }
    } catch (e) {
      setIsChatbotDeleting(false);
      toastError(e["response"]?.data?.message);
    }
  };

  const handleConfirmCloning = async () => {
    const response = await chatbotService.cloneBot({clientPromptId})
    if(response?.success) {
      setIsCloneProcessingModalOpen(true)
    } else {
      toastError(response?.message)
    }
  }

  const onChange =
    (name: any) =>
    ({ target }: any) => {
      setValue(name, target.value);
      setErrorMessage("");
    };

  const renderInput = (inputProps = {}) => {
    return (
      <Input
        {...{
          register,
          errors,
          watch,
          onChange,
          ...inputProps,
          required: "Please enter chatbot name.",
        }}
      />
    );
  };

  return (
    <>
      <div className="project_lists" style={{marginTop: `${quotaDetails?.data?.isQuestionQuotaToBeExpired && "2rem"}`}}>
        <div className="container-fluid">
          <div className="page-title">
            <h4 className="my-4">Chatbot List</h4>
            <h2>{isSubmitting}</h2>
            {/* {projects["projects"]?.length > 0 && ( */}
            <select
              name=""
              className="form-select product-list-dropdown capitalize"
              onChange={(e) => handleProjectChange(e.target.value)}
              value={currentProjectId}
            >
              {projects["projects"]?.length > 0 ? (
                projects["projects"]?.map((option) => (
                  <option
                    key={option?.title}
                    value={option.clientProjectId}
                    className="capitalize"
                  >
                    {option.title}
                  </option>
                ))
              ) : (
                <option>
                  {!!projects["isLoaded"] || !projects["isLoading"]
                    ? "No data found"
                    : !!projects["isLoading"] && "Loading Projects..."}
                </option>
              )}
            </select>
            {/* )} */}
          </div>
          {!chatbots["isLoading"] ? (
            <div className="row">
              {chatbots["chatbots"] &&
                chatbots["chatbots"]?.map(
                  (chatbot: ChatbotList, index: number) => {
                    return (
                      <div
                        key={chatbot.promptId + "_" + index}
                        className="col-md-12 col-lg-4 pb-4 card-pointer"
                        onClick={() => goToDetailPage(chatbot)}
                      >
                        <Tooltip title={chatbot?.name} placement="top">
                          <div className="__inner">
                            <div className="name">
                              {/* <CastleOutlinedIcon /> */}
                              <span className="material-symbols-outlined">
                                app_registration
                              </span>
                              <h2 className="textElipses">{chatbot?.name}</h2>
                              {!isStaffAccess && (
                                <span
                                  id="demo-positioned-button"
                                  aria-controls={
                                    openDropdown
                                      ? "demo-positioned-menu"
                                      : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={
                                    openDropdown ? "true" : undefined
                                  }
                                  onClick={(e: any) =>
                                    dropdownHandleClick(e, chatbot)
                                  }
                                  className="material-symbols-outlined
                        "
                                >
                                  more_vert
                                </span>
                              )}
                              {/* <MoreHorizOutlinedIcon
                          id="demo-positioned-button"
                          aria-controls={
                            openDropdown ? "demo-positioned-menu" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={openDropdown ? "true" : undefined}
                          onClick={(e: any) => dropdownHandleClick(e, chatbot)}
                        /> */}
                              <Menu
                                id="demo-positioned-menu"
                                aria-labelledby="demo-positioned-button"
                                anchorEl={anchorEl}
                                open={openDropdown}
                                onClose={dropdownHandleClose}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                              >
                                <MenuItem
                                  onClick={(e: any) =>
                                    dropdownHandleClone(e)
                                  }
                                >
                                  Clone bot
                                </MenuItem>
                                <MenuItem
                                  onClick={(e: any) =>
                                    dropdownHandleEdit(e, true)
                                  }
                                >
                                  Edit
                                </MenuItem>
                                <MenuItem
                                  onClick={(e: any) => dropdownHandleDelete(e)}
                                >
                                  Delete
                                </MenuItem>
                              </Menu>
                            </div>
                          </div>
                        </Tooltip>
                        <div className="__notification-area">
                          <div className="icons">
                            <img className="pdfIconSize" src={pdfIcon} />
                            <span className="counts">
                              {chatbot["embeddingCounts"]?.pdf ?? 0}
                            </span>

                            {/* <img className="csvIconSize" src={csv} />
                          <span className="counts">
                            {chatbot["embeddingCounts"]?.csv ?? 0}
                          </span> */}

                            <img className="linkIconSize" src={linkIcon} />
                            <span className="counts">
                              {chatbot["embeddingCounts"]?.webUrl ?? 0}
                            </span>

                            <img className="txtIconSize" src={txtIcon} />
                            <span className="counts">
                              {chatbot["embeddingCounts"]?.text ?? 0}
                            </span>

                            <img className="faqIconSize" src={faqIcon} />
                            <span className="faqCounts">
                              {chatbot["embeddingCounts"]?.qanda ?? 0}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}

              {!isStaffAccess &&
                chatbots !== undefined &&
                chatbots["isLoading"] == false && (
                  <div className="col-lg-3 pb-4 card-pointer">
                    <div
                      style={{ height: "120px" }}
                      className="empty_inner"
                      onClick={(e) => handleClickOpen(e, false)}
                    >
                      <span>
                        <AddOutlinedIcon fontSize="inherit" /> New Chatbot
                      </span>
                    </div>
                  </div>
                )}
            </div>
          ) : (
            <div className="chatbotLoader">
              <CircularProgress color="inherit" size={"1.5rem"} />
            </div>
          )}
        </div>
      </div>
      {open && (
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle onClose={handleClose}>
            {isEdit ? "Update Chatbot Details" : "Add New Chatbot"}
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <form onSubmit={handleSubmit(onSubmit)} className="add-project">
              <div className="row">
                <div className="form-group mb-4 col-lg-12">
                  <label>Prompt</label>
                  <select className="form-select" {...register("promptId")}>
                    {promptData.map((option, i) => (
                      <option
                        key={i + "" + option?.title}
                        value={option.promptId}
                      >
                        {option.title}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group mb-4 col-lg-12">
                  <label htmlFor="">Chatbot Name</label>
                  {/* <input
                    type="text"
                    className="form-control"
                    placeholder="Enter chatbot name"
                  /> */}
                  {renderInput(inputsConfig.chatbotName)}
                  {!!errors.name && <Error message={errors?.name?.message} />}
                </div>
                {/* <div className="form-group mb-4 col-lg-12">
                  <label htmlFor="">Category</label>
                  <select name="" className="form-select" id="">
                    <option value="1">Category One</option>
                    <option value="2">Category Two</option>
                  </select>
                </div> */}
              </div>
              <DialogActions>
                <Button
                  autoFocus
                  className="save-btn mx-auto"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <CircularProgress color="inherit" size={"1.5rem"} />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </BootstrapDialog>
      )}
      {isAlertOpen && (
        <DeleteConfirmation
          onCancel={() => setIsAlertOpen(false)}
          isOpen={isAlertOpen}
          description="Are you sure you want to delete this chatbot?"
          onAccept={handleDelete}
          onLoading={isChatbotDeleting}
        />
      )}
      {
        isCloneModalOpen && (
          <AlertConfirmation
            description="Are you sure you want to clone this chatbot?"
            onAccept={handleConfirmCloning}
            onCancel={() => setIsCloneModalOpen(false)}
          />
        )
      }
      {
        isCloneProcessingModalOpen && (
          <Modal
            open={isCloneProcessingModalOpen}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
              <Box className="boxModal" sx={{ width: '30rem', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', boxShadow: 24, p: 4, borderRadius: '10px', backgroundColor: "white", outline: "none" }}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Cloning in progress
                </Typography>
                <Typography id="modal-modal-description" sx={{ my: 2 }}>
                  {Object.entries(cloningItems).map(([key, value]) => (
                    <Box key={key} sx={{ mb: 3 }}>

                      <span className="progressBarLabelContainer">
                        <Typography sx={{ mb: 1 }} variant="body2">{key.toUpperCase()}</Typography>
                        {
                          value === 100 && (
                            <img src={assets.images.check} width={20} height={20} className="checkmark" />
                          )
                        }
                      </span>
                        <LinearProgress variant="determinate" value={value} />
                    </Box>
                  ))}
                </Typography>
                <button onClick={() => {
                  setIsCloneProcessingModalOpen(false)
                  setCloningItems({"General Setting": 10, "Theme Setting": 30, "Actions": 15, "Integrations": 70, "Content Store": 20, "Q&A": 28})
                }} className="cloneModalButton">OK</button>
              </Box>
          </Modal>
        )
      }
    </>
  );
};

export default Chatbots;
