import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import { toastError, toastSuccess } from "helpers/toastHelper";
import { useEffect, useState, useId, useMemo } from "react";
import { promptService } from "services";
import { Error } from "components/auth/Error";
import { Input } from "components/auth/Input";
import React from "react";
import "react-multi-email/dist/style.css";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import { v4 as uuidv4 } from "uuid";
import {
  getValidationTypeFromStore,
  selectValidationType,
  selectCustomValidationParameter,
  getCustomValidationParameterFromStore,
} from "redux/features/dataParameterValidation";
import { chatbotService } from "services";
import {
  ValidationType,
  customValidationParameter,
} from "types/dataParameterValidation";
import { useAppDispatch, useTypedSelector } from "redux/store";
import DataParameter from "components/dataParameter";

const BootstrapDialog = styled(Dialog)(({ theme }: any) => ({
  "& .MuiDialogContent-root": {
    padding: theme?.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme?.spacing(1),
  },
}));

function BootstrapDialogTitle(props: any) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const inputsConfig = {
  title: {
    name: "title",
    placeholder: "Enter title",
    className: "form-control",
    type: "text",
    required: "Prompt title is required",
  },
  prompt: {
    name: "prompt",
    placeholder: "Enter prompt content",
    className: "form-control",
    type: "text",
    required: "Prompt content is required",
    maxRows: 25,
    minRows: 8,
    multiline: true,
  },
  description: {
    name: "description",
    placeholder: "Enter description",
    className: "form-control",
    type: "text",
    maxRows: 4,
    minRows: 3,
    multiline: true,
  },
  parameter: {
    name: "parameter",
    placeholder: "Enter parameter",
    className: "form-control",
    type: "text",
    maxRows: 4,
    minRows: 3,
    multiline: true,
  },
};

const validation = {
  validationId: "",
  validationOperation: "",
  validationOperator: "",
  validationValue: "",
  dataJson: [],
  dateFormat: [],
};

const initialParam = {
  id: "",
  value: "",
  minValue: "",
  maxValue: "",
  key: uuidv4(),
  isMandatory: false,
  validation: "No validation",
  customValidation: [validation],
  parameterType: "",
  isOptionsExist: false,
  rangePickerMinMsg: "",
  rangePickerMaxMsg: "",
  optionMsg: "",
};

export const CreatePrompt = ({
  open = false,
  handleClose = () => null,
  detail = null,
  getPrompt = () => null,
  captureLeadValue = false,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inputType, setInputType] = useState([]);
  const [captureLead, setCaptureLead] = React.useState(false);
  const [dataParameters, setDataParameters] = React.useState([initialParam]);
  const validationTypeList: ValidationType =
    useTypedSelector(selectValidationType);
  const customValidationParameterList: customValidationParameter =
    useTypedSelector(selectCustomValidationParameter);
  const dispatch = useAppDispatch();

  useMemo(() => {
    if (!!detail) {
      setValue("title", detail?.title || "");
      setValue("prompt", detail?.prompt || "");
      setValue("description", detail?.shortDescription || "");

      if (detail?.dataParameters == null) {
        setDataParameters([initialParam]);
      } else if (detail?.dataParameters.length > 0) {
        const data = structuredClone(detail?.dataParameters);
        setDataParameters(
          data?.map((item: any) => {
            item["key"] = item.id;
            return item;
          })
        );
      } else {
        setDataParameters([initialParam]);
      }
      setCaptureLead(true)
    }
  }, [detail]);

  useEffect(() => {
    getValidationType();
    getCustomValidationParameter();
  }, []);

  const getValidationType = () => {
    let payload = {};
    dispatch(getValidationTypeFromStore(payload));
    getParameterInputTypeHandler();
  };

  const getCustomValidationParameter = () => {
    let payload = {};
    dispatch(getCustomValidationParameterFromStore(payload));
  };

  const onChange =
    (name: any) =>
      ({ target }: any) => {
        setValue(name, target.value);
      };

  const onSubmit = async ({ title, prompt, description }) => {
    try {
      let newDataParameters = dataParameters.filter((item) => item.id != "");
      newDataParameters.forEach((el) => {
        el.key = el.id
        if (el.parameterType == "range-picker") {
          el.value = el.minValue + "," + el.maxValue;
        }
      });
      setIsSubmitting(true);
      if (!!title && !!prompt) {
        const payload = {
          title,
          prompt,
          shortDescription: description,
          categoryId: 1,
          cost: 100,
          currency: "INR",
          captureLead: true,
        };
        let response = null;
        if (!detail) {
          payload["dataParameters"] = !!newDataParameters.length
            ? newDataParameters
            : null;
          response = await promptService.createPrompt(payload);
        } else if (!!detail?.promptId) {
          payload["promptId"] = detail?.promptId;
          payload["dataParameters"] = !!newDataParameters.length
            ? newDataParameters
            : null;
          response = await promptService.updatePrompt(payload);
        }
        if (!!response) toastSuccess(response?.data?.message);
      }
      getPrompt();
    } catch (e) {
      toastError(e["response"]?.data?.message);
      setIsSubmitting(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderInput = (inputProps = {}) => {
    return (
      <Input
        {...{
          register,
          errors,
          watch,
          onChange,
          ...inputProps,
        }}
      />
    );
  };
  // const getCaptureLead = (event) => {
  //   let { checked } = event.target;
  //   setCaptureLead(checked);
  // };

  const addNewRows = (e) => {
    e.stopPropagation();
    initialParam.key = uuidv4();
    setDataParameters([...dataParameters, initialParam]);
  };

  const deleteRows = (event, index) => {
    event.preventDefault();
    const deletedData = [...dataParameters];
    deletedData.splice(index, 1);
    setDataParameters(deletedData);
  };

  const getDataParameter = (tempDataParameter) => {
    setDataParameters(tempDataParameter);
  };

  const getMandatory = (event, i) => {
    let { checked } = event.target;
    if (dataParameters?.length > 0) {
      const onChangeMandatoryVal = structuredClone(dataParameters);
      onChangeMandatoryVal[i].isMandatory = checked;
      setDataParameters(onChangeMandatoryVal);
    }
  };

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const _items: any = reorder(
      dataParameters,
      result.source.index,
      result.destination.index
    );

    setDataParameters(_items);
  };

  const isShowValidationOption = (
    dpIndex: number,
    slug: string,
    isMultiple: boolean
  ) => {
    const dataParameter = structuredClone(dataParameters);
    let isDisabled = false;
    if (!isMultiple) {
      isDisabled = !!dataParameter[dpIndex]?.customValidation?.find(
        (val) => val?.validationOperation == slug
      );
    }
    return isDisabled;
  };

  const handleValidatonChange = (event: any, i) => {
    let { value } = event.target;

    if (dataParameters?.length > 0) {
      const validationData = structuredClone(dataParameters);
      validationData[i].validation = value;
      if (value == "custom-validation") {
        validationData[i].customValidation = [validation];
      } else if (value == "date") {
        customValidationParameterList.filter((item) => {
          if (item["parentSlug"] == value) {
            validationData[i].customValidation[0].validationOperation =
              item.slug;

            validationData[i].customValidation[0].dataJson = item.dataJson;

            validationData[i].customValidation[0].dateFormat =
              item["validationValue"];

            validationData[i].customValidation[0].validationOperator =
              "equal-to";

            setDataParameters(validationData);
          }
        });
      }
      setDataParameters(validationData);
    }
  };

  const handleCustomValidationChange = (parentIndex, event, i, text) => {
    let { name, value } = event.target;

    const customValidationData = structuredClone(dataParameters);

    customValidationParameterList.filter((item) => {
      if (item.slug == value) {
        customValidationData[parentIndex].customValidation[i].dataJson =
          item.dataJson;

        customValidationData[parentIndex].customValidation[i].dateFormat =
          item["validationValue"];
        setDataParameters(customValidationData);
      }
    });
    customValidationData[parentIndex].customValidation[i][name] = value;
    setDataParameters(customValidationData);
  };

  const addCustomValidationNewRows = (parentIndex, event, i) => {
    const customValidationData = structuredClone(dataParameters);
    customValidationData[parentIndex].customValidation.push(validation);

    setDataParameters(customValidationData);
  };

  const deleteCustomValidationRows = (parentIndex, event, i) => {
    event.preventDefault();
    if (i == 0) {
      const resetData = [...dataParameters];
      resetData[parentIndex].customValidation[0] = validation;
      setDataParameters(resetData);
    } else {
      const deletedData = [...dataParameters];
      deletedData[parentIndex].customValidation.splice(i, 1);
      setDataParameters(deletedData);
    }
  };

  const handleInputTypeChange = (tempDataParameter) => {
    setDataParam(tempDataParameter);
  };

  const setDataParam = (data: any) => {
    setDataParameters(data);
  };

  const getParameterInputTypeHandler = async () => {
    try {
      const res = await chatbotService.getParameterInputType({});
      if (res?.success && !!res?.data) setInputType(res?.data);
    } catch (err) { }
  };

  return (
    <div className="promptDilog">
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        className="promptDilog"
        open={open}
      >
        <BootstrapDialogTitle onClose={handleClose}>
          {!!detail ? "Update prompt details" : "Add new prompt"}
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <form onSubmit={handleSubmit(onSubmit)} className="add-project">
            <div className="row">
              <div className="form-group col-lg-12">
                <label htmlFor="">Title</label>
                {renderInput(inputsConfig.title)}
              </div>
              {!!errors.title && <Error message={errors.title.message} />}
              <div className="form-group mt-4 col-lg-12">
                <label htmlFor="">Prompt content</label>
                {renderInput(inputsConfig.prompt)}
              </div>
              {!!errors.prompt && <Error message={errors.prompt.message} />}
              {/* <div className="form-group mt-4 col-lg-12">
                <label htmlFor="">Short description</label>
                {renderInput(inputsConfig.description)}
              </div> */}

              <div className="form-group mt-4 col-lg-12">
                <div className="d-flex align-item-center mr-4">
                  <label htmlFor="">Capture Leads</label>
                  <Checkbox
                    className="checkBoxSize"
                    checked={true}
                    disabled
                  />
                </div>
              </div>

              <div className="form-group col-lg-12 mt-4">
                <label htmlFor="">
                  Data Parameters (to be fetched from conversation)
                </label>

                <DataParameter
                  onDragEnd={onDragEnd}
                  dataParameterList={dataParameters}
                  getDataParameter={getDataParameter}
                  handleInputTypeChange={handleInputTypeChange}
                  inputTypeList={inputType}
                  addNewRows={addNewRows}
                  deleteRows={deleteRows}
                  handleValidatonChange={handleValidatonChange}
                  validationTypeList={validationTypeList}
                  customValidationParameterList={customValidationParameterList}
                  isShowValidationOption={isShowValidationOption}
                  handleCustomValidationChange={handleCustomValidationChange}
                  addCustomValidationNewRows={addCustomValidationNewRows}
                  deleteCustomValidationRows={deleteCustomValidationRows}
                />
              </div>

              <div className="form-group mt-4 col-lg-12">
                <DialogActions className="p-0">
                  <Button
                    disabled={isSubmitting}
                    className="save-btn mx-auto"
                    type="submit"
                  >
                    {isSubmitting ? (
                      <Box sx={{ display: "flex" }}>
                        <CircularProgress color="inherit" size={"1.5rem"} />
                      </Box>
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </DialogActions>
              </div>
            </div>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};
