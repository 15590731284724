import React, { useEffect, useMemo, useState } from "react";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import {
  Button,
  Checkbox,
  IconButton,
  Stack,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import "./styles.css";
import { useForm } from "react-hook-form";
import { Error } from "components/auth/Error";
import { Input } from "components/auth/Input";
import {
  getPricingFeatureFromStore,
  selectPricingFeature,
} from "redux/features/pricingFeatureSlice";
import { useAppDispatch, useTypedSelector } from "redux/store";
import {
  getPricingFromStore,
  selectPricing,
} from "redux/features/pricingSlice";
import { pricingService } from "services";
import { toastSuccess } from "helpers/toastHelper";

type Props = {};

interface MembershipState {
  subscriptionFeaturesList: any[];
  hasEdited: boolean;
}

const initialState: MembershipState = {
  subscriptionFeaturesList: [],
  hasEdited: false
};

const Membership = (props: Props) => {
  const dispatch = useAppDispatch();
  const pricingFeatures: Array<any> = useTypedSelector(selectPricingFeature);
  const subscriptionList: Array<any> = useTypedSelector(selectPricing);
  const [{ 
    subscriptionFeaturesList = [],
    hasEdited
  }, setState] = useState(initialState);

  useEffect(() => {
    getPricingFeature();
    getPricing();
  }, []);

  useMemo(()=> {
    if(Array.isArray(pricingFeatures) && Array.isArray(subscriptionList)){
      let _pricingFeatures: any[] = structuredClone(pricingFeatures)?.filter(item => item?.status);
      let priceSub = [];
      let yearlyPriceSub = [];
      const _subscriptionList: any[] = structuredClone(subscriptionList)?.map(item => {
        priceSub = [...priceSub, {
          packageId: item?.packageId,
          title: item?.title,
          value: Number(item?.price) || 0
        }]
        yearlyPriceSub = [...yearlyPriceSub, {
          packageId: item?.packageId,
          title: item?.title,
          value: Number(item?.yearlyPrice) || 0
        }]
      
        return ({
          yearlyPrice: item?.yearlyPrice,
          price: item?.price,
          packageId: item?.packageId,
          title: item?.title,
          value: '' 
        })
      })
   
      const preRequisetPriceFeatures = [
        {
          packageFeatureId: "price",
          title: "Monthly Price",
          type: "number",
          subscriptions: priceSub
        },
        {
          packageFeatureId: "yearlyPrice",
          title: "Yearly Price",
          type: "number",
          subscriptions: yearlyPriceSub
        }
      ];
     
      _pricingFeatures = [...preRequisetPriceFeatures, ..._pricingFeatures];
      _pricingFeatures?.map((item, i) => {
        if(i === 0 || i === 1) return item;
        return item.subscriptions = _subscriptionList || [];
      })
     
      if(!!subscriptionList[0]?.features?.length){
        _pricingFeatures?.map((item, i) => {
          if(i === 0 || i === 1) return item;
          let _subscriptions = structuredClone(item?.subscriptions)?.map(sub => {
            let _features = subscriptionList?.find(_sub => _sub?.packageId == sub?.packageId)?.features;
            let value = _features?.find(fea => fea?.packageFeatureId == item.packageFeatureId)?.value;
            sub.value = value;
            return sub;
          })
          item.subscriptions = _subscriptions;
          return item;
        });  
      }

      setState(prev => ({
        ...prev,
        subscriptionFeaturesList: _pricingFeatures
      }))
    }
  }, [pricingFeatures, subscriptionList])

  const getPricingFeature = async () => {
    dispatch(
      getPricingFeatureFromStore({
        range: {
          page: 1,
          pageSize: 1000,
        },
      })
    );
  };

  const getPricing = async () => {
    dispatch(
      getPricingFromStore({
        range: {
          page: 1,
          pageSize: 100,
        },
      })
    );
  };

  const onChangeValue = (event, i, j, type) => {
    const target = event.target;
    const _subscriptionFeaturesList: any[] = JSON.parse(JSON.stringify(subscriptionFeaturesList));
    let value = target?.value || '';
    if(type === "checkbox"){
      value = target?.checked;
    } 
    _subscriptionFeaturesList[i].subscriptions[j].value = value;
    setState(prev => ({
      ...prev,
      hasEdited: true,
      subscriptionFeaturesList: _subscriptionFeaturesList
    }))
  }

  const onClickSave = async () => {
    if(!hasEdited) return;
    try{
      const payload: any[] = structuredClone(subscriptionList)?.map((item, i) => {
        let features = [];
        subscriptionFeaturesList.forEach((subFeature, j) => {
          if(j === 0){
            item.price = !!subFeature?.subscriptions[i]?.value ? Number(subFeature?.subscriptions[i]?.value) : Number(item.price);
          } else if(j === 1) {
            item.yearlyPrice = !!subFeature?.subscriptions[i]?.value ? Number(subFeature?.subscriptions[i]?.value) : Number(item.yearlyPrice);
          } else{
            const value = subFeature?.subscriptions?.find(sub => sub?.packageId === item?.packageId)?.value || '';
            features = [...features, {slug: subFeature?.slug, value: value != '' ? value : subFeature?.type === "checkbox" ? false : ''}];
          }
        })
        return ({
          packageId: item?.packageId,
          price: item?.price,
          yearlyPrice: item?.yearlyPrice,
          features: features
        });
      })
      if(!payload?.length) return;
      const response = await pricingService.updatePricing(payload);
      if (response?.data?.success) {
        toastSuccess("Subscription updated successfully!");
        getPricing();
        setState((prevState) => ({
          ...prevState,
          hasEdited: false,
        }));
      }
    }catch(err){
        
    }
  }

  // console.log("subscriptionFeaturesList=======", subscriptionFeaturesList, subscriptionList)
  return (
    <div className="membership-section">
      <div className="project_lists">
        <div className="container-fluid">
          <div className="chat_title">
            <h4 className="my-4">Membership</h4>
            <Button
                variant="outlined"
                className={hasEdited ? "chat_add_button" : "chat_gray_button"}
                onClick={onClickSave}
              >
                Save Changes 
            </Button>
          </div>
          <div
            style={{ height: "75vh", width: "100%", backgroundColor: "white" }}
          >
            <div className="table-responsive cstm-table-subscription m-t-10">
              <table className="table">
                  <thead>
                      <tr>
                          <th>Features</th>
                          {!!subscriptionList?.length && subscriptionList?.map((item: any, index: number) => {
                            return(
                              <th className="txt-center capitalize" key={`${index}_${item.title}`}>{item.title}</th>
                            )
                          })}
                      </tr>
                  </thead>
                  <tbody>
                    {!!subscriptionFeaturesList?.length && subscriptionFeaturesList?.map((item: any, i: number) => {
                      return(
                        <tr key={`${i}_${item?.title}`}>
                            <td>{item?.title}</td>
                            {!!item?.subscriptions?.length && item?.subscriptions?.map((sub: any, j: number) => {
                              return(
                                <td key={`${sub?.title}`} className="txt-center">
                                  {item?.type !== 'checkbox' && <input type={item?.type ? item?.type : "text"} className="form-control numOfUsers" value={sub.value} onChange={(event)=> onChangeValue(event, i, j, item?.type)}/>}
                                  {item?.type === 'checkbox' && <input type="checkbox" checked={sub.value} className="largerCheckbox" onChange={(event)=> onChangeValue(event, i, j, item?.type)}/>}
                                </td>
                              )
                            })}
                        </tr>
                      )
                    })}
                      
                  </tbody>
              </table>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Membership;
