import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import {
  Button,
  Checkbox,
  IconButton,
  Stack,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import "./styles.css";
import { useForm } from "react-hook-form";
import { Error } from "components/auth/Error";
import { Input } from "components/auth/Input";
import {
  getPricingFeatureFromStore,
  selectPricingFeature,
} from "redux/features/pricingFeatureSlice";
import { useAppDispatch, useTypedSelector } from "redux/store";
import { CustomNoRowsOverlay } from "components/NORowsSVG";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { pricingFeatureService } from "services";
import { toastError, toastSuccess } from "helpers/toastHelper";
import { getFormatedDate } from "helpers/common";
import DeleteConfirmation from "components/deleteConfirmation";

type Props = {};

const BootstrapDialog = styled(Dialog)(({ theme }: any) => ({
  "& .MuiDialogContent-root": {
    padding: theme?.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme?.spacing(1),
  },
}));

function BootstrapDialogTitle(props: any) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const inputsConfig = {
  title: {
    name: "title",
    placeholder: "Enter title",
    className: "form-control",
    type: "text",
    required: "Enter title",
  },
};

interface FeatureState {
  open: boolean;
  isEditMode: boolean;
  isShowAlert: boolean;
  isDeleting: boolean;
  featureDetail: any;
  featureType: any;
  page: number;
  pageSize: number;
}

const initialState = {
  open: false,
  isEditMode: false,
  isShowAlert: false,
  isDeleting: false,
  featureDetail: null,
  featureType: null,
  page: 1,
  pageSize: 20
};

const Feature = (props: Props) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm();

  const [{ open, isEditMode, featureDetail, featureType, isShowAlert, isDeleting, page, pageSize }, setState] =
    useState(initialState);
  const dispatch = useAppDispatch();
  const pricingFeature: Array<any> = useTypedSelector(selectPricingFeature);

  const columns: GridColDef[] = [
    {
      field: "title",
      headerName: "Title",
      minWidth: 300,
      cellClassName: "capitalize",
      flex: 1,
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 200,
      cellClassName: "capitalize",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      valueFormatter: (params) => getFormatedDate(params?.value),
      minWidth: 200,
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <Stack direction="row" spacing={1}>
            <Tooltip title="View" placement="right">
              <IconButton
                aria-label="view"
                // color="primary"
                onClick={(e) => handleEdit(e, cellValues)}
              >
                <DriveFileRenameOutlineOutlinedIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Feature" placement="right">
              <IconButton
                aria-label="delete"
                onClick={(e: any) => handleDelete(e, cellValues)}
              >
                <span className="material-symbols-outlined">delete</span>
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  useEffect(() => {
    getPricingFeature();
  }, []);

  const getPricingFeature = async (p = page, ps = pageSize) => {
    dispatch(
      getPricingFeatureFromStore({
        range: {
          page: p,
          pageSize: ps,
        },
      })
    );
    handleClose();
  };

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      open: false,
      isShowAlert: false,
      featureDetail: null
    }));
  };

  const onChange =
    (name: any) =>
    ({ target }: any) => {
      setValue(name, target.value);
    };

  const renderInput = (inputProps = {}) => {
    return (
      <Input
        {...{
          register,
          errors,
          watch,
          onChange,
          ...inputProps,
          required: "please enter title.",
        }}
      />
    );
  };

  const handleEdit = (e, cellValue) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      open: true,
      isEditMode: true,
      featureDetail: cellValue?.row,
      featureType: cellValue?.row?.type,
    }));
    setValue("title", cellValue.row.title);
  };

  const handleDelete = (e, cellValue) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      isShowAlert: true,
      featureDetail: cellValue?.row || null,
    }));
  };

  const handleDeleteHandler = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isDeleting: true,
      }));
      if (!!featureDetail?.packageFeatureId) {
        const payload = {
          packageFeatureId: featureDetail?.packageFeatureId,
        };
        const response = await pricingFeatureService.deleteFeature(payload);
        if (response?.data.success) {
          toastSuccess(response?.data?.message);
          getPricingFeature();
          setState((prev) => ({
            ...prev,
            isDeleting: false,
            featureDetail: null,
            isShowAlert: false,
          }));
        }
      }
    } catch (e) {
      setState((prevState) => ({
        ...prevState,
        isDeleting: false,
      }));
      toastError(e["response"]?.data?.message);
    }
  };

  const openPlanPopup = () => {
    setState((prevState) => ({
      ...prevState,
      open: true,
      isEditMode: false,
    }));
    setValue("title", "");
  };

  const handleFeatureType = (event) => {
    let { value } = event.target;
    setState((prevState) => ({
      ...prevState,
      featureType: value,
    }));
  };

  const onSubmit = async ({ title }) => {
    try {
      let payload = {
        title: title,
        type: featureType,
      };
      if (!!featureDetail) {
        payload["packageFeatureId"] = featureDetail?.packageFeatureId;
      }
      const response = !!featureDetail ? await pricingFeatureService.updatePricingFeature(payload) : await pricingFeatureService.createClient(payload);
      if (response?.data?.success) {
        toastSuccess(
          response?.data?.message
            ? response?.data?.message
            : !!featureDetail
            ? "Feature updated successfully!"
            : "Feature created successfully!"
        );
        getPricingFeature();
        setState((prevState) => ({
          ...prevState,
          open: false,
        }));
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        open: false,
      }));
    }
  };

  return (
    <>
      <div className="project_lists">
        <div className="container-fluid">
          <div className="chat_title">
            <h4 className="my-4">Feature</h4>
            <Button
              variant="outlined"
              className="chat_add_button"
              onClick={openPlanPopup}
            >
              <AddOutlinedIcon fontSize="inherit" /> {"  "} Add Feature
            </Button>
          </div>
          <div
            style={{ height: "75vh", width: "100%", backgroundColor: "white" }}
          >
            <DataGrid
              rows={pricingFeature}
              columns={columns}
              getRowId={(row) => row?.packageFeatureId}
              slots={{
                columnMenu: CustomNoRowsOverlay,
              }}
              initialState={{
                pagination: {
                  paginationModel: { page: 1, pageSize: 20 },
                },
              }}
              pageSizeOptions={[10, 20, 30, 40, 50]}
            />
          </div>
        </div>
      </div>

      {!!open && (
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle onClose={handleClose}>
            {isEditMode ? "Update Package" : "Create new package"}
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <form onSubmit={handleSubmit(onSubmit)} className="add-project">
              <div className="row">
                <div className="form-group col-lg-12">
                  <label htmlFor="">Title</label>
                  {renderInput(inputsConfig.title)}
                </div>
                {!!errors.title && <Error message={errors.title.message} />}

                <div className="form-group col-lg-12 mt-3">
                  <label htmlFor="">Feature Type</label>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="checkbox"
                      control={<Radio />}
                      label="Checkbox"
                      checked={featureType == "checkbox"}
                      onChange={(event) => handleFeatureType(event)}
                    />
                    <FormControlLabel
                      value="number"
                      control={<Radio />}
                      label="Number"
                      checked={featureType == "number"}
                      onChange={(event) => handleFeatureType(event)}
                    />
                    <FormControlLabel
                      value="text"
                      control={<Radio />}
                      label="Text"
                      checked={featureType == "text"}
                      onChange={(event) => handleFeatureType(event)}
                    />
                  </RadioGroup>
                </div>
                <div className="form-group mt-4 col-lg-12">
                  <DialogActions className="p-0">
                    <Button
                      // disabled={isSubmitting}
                      className="save-btn mx-auto"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </DialogActions>
                </div>
              </div>
            </form>
          </DialogContent>
        </BootstrapDialog>
      )}

      {isShowAlert && (
        <DeleteConfirmation
          onCancel={() => {
            handleClose();
          }}
          isOpen={isShowAlert}
          onAccept={handleDeleteHandler}
          onLoading={isDeleting}
        />
      )}
    </>
  );
};

export default Feature;
