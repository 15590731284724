import { useCallback, useEffect, useRef, useState } from "react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import {
  getUserFromStore,
  selectCurrentUser,
  setAppState,
  updateSuperAdminState,
} from "redux/features/appStateSlice";
import { useAppDispatch, useTypedSelector } from "redux/store";
import { ClientsState, ClientsResponse } from "types/Clients";
import { toastError, toastSuccess } from "helpers/toastHelper";
import DeleteConfirmation from "components/deleteConfirmation";
import { getClientFromStore, selectClients } from "redux/features/clientSlice";
import { CreateClient } from "components/clients/createClient";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { Button, IconButton, Stack, TextField } from "@mui/material";
import { authService, clientService } from "services";
import {
  getToken,
  getUser,
  setOldToken,
  setOldUser,
  setToken,
} from "helpers/storage";
import { getFormatedDate } from "helpers/common";
import { resetChatbot, selectChatbotDetails } from "redux/features/chatbotSlice";
import { resetInbox } from "redux/features/inboxSlice";
import { resetPF } from "redux/features/pricingFeatureSlice";
import { resetPricing } from "redux/features/pricingSlice";
import { resetProject } from "redux/features/projectSlice";
import { resetPrompt } from "redux/features/promptSlice";
import { resetQusAns } from "redux/features/questionAnswerSlice";
import { ChatbotList } from "types/Chatbot";

type Props = {};

const initialState: ClientsState = {
  open: false,
  details: null,
  page: 0,
  pageSize: 20,
  isDeleting: false,
  isLoading: false,
};

function useDebounce(func, delay) {
  const timeoutRef = useRef(null);

  return useCallback((...args) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      func(...args);
    }, delay);
  }, [func, delay]);
}

const Clients = (props: Props) => {
  const navigate = useNavigate();
  const [{ open, details, page, pageSize, isLoading, isDeleting }, setState] =
    useState(initialState);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [search, setSearch] = useState("")
  const dispatch = useAppDispatch();
  const user = useTypedSelector(selectCurrentUser);
  const clients: Array<ClientsResponse> = useTypedSelector(selectClients);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 200,
      cellClassName: "capitalize",
      flex: 1,
    },
    { field: "email", headerName: "Email", minWidth: 300, flex: 1 },
    {
      field: "createdAt",
      headerName: "Created At",
      minWidth: 200,
      flex: 1,
      valueFormatter: (params) => getFormatedDate(params?.value),
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 300,
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <Stack direction="row" spacing={1}>
            <IconButton
              aria-label="edit"
              onClick={(e: any) => onEditHandle(e, cellValues)}
            >
              <span className="material-symbols-outlined">border_color</span>
            </IconButton>
            <IconButton
              aria-label="delete"
              onClick={(e: any) => dropdownHandleDelete(e, cellValues)}
            >
              <span className="material-symbols-outlined">delete</span>
            </IconButton>
            <IconButton
              aria-label="login"
              onClick={(e: any) => loginAsClient(e, cellValues)}
            >
              <span className="material-symbols-outlined">exit_to_app</span>
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const loginAsClient = async (event, data) => {
    if (!!data && !!data?.row?.clientId) {
      let oldToken = await getToken();
      let oldUser = await getUser();
      const payload = {
        clientId: data?.row?.clientId,
      };
      const response: any = await authService.signInAsClient(payload);
      if (response?.success && response.token) {
        dispatch(getUserFromStore({ token: response.token, user: response?.data, dispatch, isSetToken: true }));
        // dispatch(setAppState({ token: response.token, user: response?.data, dispatch }));
        cleanAllState();
        setOldToken(oldToken);
        setOldUser(oldUser);
        navigate("/projects", { replace: true });
        dispatch(updateSuperAdminState({ isShow: true }));
      }
    }
  };

  const cleanAllState = () => {
    dispatch(resetChatbot);
    dispatch(resetInbox);
    dispatch(resetPF);
    dispatch(resetPricing);
    dispatch(resetProject);
    dispatch(resetPrompt);
    dispatch(resetQusAns);
  };

  const onEditHandle = (event, data) => {
    event.stopPropagation();
    setState((prev) => ({ ...prev, open: true, details: data?.row }));
  };

  const dropdownHandleDelete = (event, data) => {
    event.stopPropagation();
    setIsAlertOpen(true);
    setState((prev) => ({ ...prev, details: data?.row }));
  };

  useEffect(() => {
    getClients();
  }, [search]);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      isLoading: clients["isLoading"],
    }));
  }, [clients["isLoading"]]);

  const getClients = async () => {
    dispatch(
      getClientFromStore({
        filter: {
          search: search
        },
        range: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
    handleClose();
  };

  const handleClickOpen = (event) => {
    event.stopPropagation();
    setState((prev) => ({ ...prev, open: true }));
  };

  const handleClose = () => {
    setState((prev) => ({
      ...prev,
      open: false,
      details: null,
    }));
  };

  const handleDelete = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isDeleting: true,
      }));
      if (!!details?.clientId) {
        const payload = {
          clientId: details?.clientId,
        };
        const response = await clientService.deleteClient(payload);
        if (response?.data.success) {
          toastSuccess(response?.data?.message);
          getClients();
          setState((prev) => ({ ...prev, details: null, isDeleting: false }));
          setIsAlertOpen(false);
        }
      }
    } catch (e) {
      setState((prevState) => ({
        ...prevState,
        isDeleting: false,
      }));
      toastError(e["response"]?.data?.message);
    }
  };

  const onModelChange = (event) => {
    setState((prevState) => ({
      ...prevState,
      page: event.page + 1,
      pageSize: event.pageSize,
    }));

    dispatch(
      getClientFromStore({
        range: {
          page: event.page + 1,
          pageSize: event.pageSize,
        },
      })
    );
  };


  const handleSearch = useCallback((value) => {
    setSearch(value)
  }, []);

  const debouncedHandleSearch = useDebounce(handleSearch, 300);

  const handleChange = (value) => {
    debouncedHandleSearch(value)
  };

  return (
    <>
      <div className="project_lists">
        <div className="container-fluid">
          <div className="chat_title">
            <h4 className="my-4">Client List</h4>
            <div>
              <TextField onChange={(e) => handleChange(e.target.value)} id="standard-basic" label="Filter by search" variant="standard" />
              <Button
                variant="outlined"
                className="addBtn chat_add_button"

                onClick={handleClickOpen}
              >
                <AddOutlinedIcon fontSize="inherit" /> {"  "}New Client
              </Button>
            </div>
          </div>
          <div
            style={{ height: "82vh", width: "100%", backgroundColor: "white" }}
          >
            <DataGrid
              pagination
              style={{border: 0}}
              paginationMode="server"
              rows={clients["clients"]}
              getRowId={(row) => row.clientId}
              columns={columns}
              loading={isLoading}
              onPaginationModelChange={onModelChange}
              rowCount={clients["total"]}
              initialState={{
                pagination: {
                  paginationModel: { page, pageSize },
                },
              }}
              pageSizeOptions={[10, 20, 30, 40, 50]}
            />
          </div>
        </div>
      </div>
      {open && (
        <CreateClient
          open={open}
          handleClose={handleClose}
          getClients={getClients}
          detail={details}
        />
      )}
      {isAlertOpen && (
        <DeleteConfirmation
          onCancel={() => {
            setIsAlertOpen(false);
            handleClose();
          }}
          isOpen={isAlertOpen}
          onAccept={handleDelete}
          onLoading={isDeleting}
        />
      )}
    </>
  );
};

export default Clients;
