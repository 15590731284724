import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getFromStore, setToStorage, storageKey } from "helpers/storage";
import { RootState } from "redux/store";
import { clientService, projectService } from "services";
import { ClientsResponse, ClientsBodyParam } from "types/Clients";
import { MetaPagination } from "types/Others";

type ClientsState = {
  clients: Array<ClientsResponse>
  meta: MetaPagination
  total: number
  isLoading: boolean
  isHigherPackageAvailable: boolean
  isLowerPackageAvailable: boolean
  isExpired: boolean
  periodLeft: string
  subscriptionDetails: any
}

export const getClientFromStore = createAsyncThunk(
  'auth/getClientsFromStore',
  async (param: ClientsBodyParam = {}) => {
    return await clientService.getClients(param);
  }
)

export const getSubscriptionInfoStore = createAsyncThunk('auth/getSubscriptionInfoStore', async () => {
  return await clientService.checkUserSubscription();
})

const initialState: ClientsState = {
  clients: [],
  meta: {},
  total: 0,
  isLoading: true,
  isHigherPackageAvailable: false,
  isLowerPackageAvailable: false,
  isExpired: false,
  periodLeft: null,
  subscriptionDetails: null
}

export const clientSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
 
  },
  extraReducers: (builder) => {
    builder.addCase(getClientFromStore.fulfilled, (state, action) => {
      if(action?.payload){
        const {data, meta, total} = action?.payload;
        state.clients = data;
        state.meta = meta;
        state.total = total;
        state.isLoading = false;
      }
    }).addCase(getClientFromStore.rejected, (state, action) => {
      state.isLoading = false;
      state.total = 0;
    });
    builder.addCase(getSubscriptionInfoStore.fulfilled, (state, action) => {
      if(action?.payload){
        const {isHigherPackageAvailable, isLowerPackageAvailable, isExpired, periodLeft} = action?.payload?.data?.subscriptionDetails;
        state.isHigherPackageAvailable = isHigherPackageAvailable;
        state.isLowerPackageAvailable = isLowerPackageAvailable;
        state.isExpired = isExpired;
        state.periodLeft = periodLeft;
        let subscriptionDetails = action?.payload?.data?.subscriptionDetails;
        if(!!subscriptionDetails){
          setToStorage(storageKey.subscription, subscriptionDetails)
        }else{
          subscriptionDetails = getFromStore(storageKey.subscription);
        }   
        state.subscriptionDetails = subscriptionDetails;
      }
    }).addCase(getSubscriptionInfoStore.rejected, (state, action) => {
      state.isHigherPackageAvailable = false;
      state.isLowerPackageAvailable = false;
      state.isExpired = false;
      state.periodLeft = null;
    });
  }
});

export const {
} = clientSlice.actions;

export default clientSlice.reducer;
export const selectClients = (state: RootState) => state.clientState;
export const selectIsHigherPackageAvailable = (state: RootState) => state.clientState?.isHigherPackageAvailable;
export const selectSubscriptionDetails = (state: RootState) => state.clientState?.subscriptionDetails;