import { useCallback, useEffect, useRef, useState } from "react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { selectCurrentUser } from "redux/features/appStateSlice";
import { useAppDispatch, useTypedSelector } from "redux/store";
import { toastError, toastSuccess } from "helpers/toastHelper";
import DeleteConfirmation from "components/deleteConfirmation";
import { getPromptsFromStore, selectPrompts } from "redux/features/promptSlice";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { Button, IconButton, Stack, TextField } from "@mui/material";
import { CreatePrompt } from "components/clients/createPrompts";
import { promptService } from "services";

type Props = {};

const initialState: any = {
  open: false,
  details: false,
  page: 1,
  pageSize: 20,
  isPromptDeleting: false,
  isLoading: false,
};

function useDebounce(func, delay) {
  const timeoutRef = useRef(null);

  return useCallback((...args) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      func(...args);
    }, delay);
  }, [func, delay]);
}


const Prompts = (props: Props) => {
  const navigate = useNavigate();
  const [
    { open, details, page, pageSize, isPromptDeleting, isLoading },
    setState,
  ] = useState(initialState);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [search, setSearch] = useState("")
  const dispatch = useAppDispatch();
  const user = useTypedSelector(selectCurrentUser);
  const prompts: Array<any> = useTypedSelector(selectPrompts);
  const [captureLead, setCaptureLead] = useState(false);

  const columns: GridColDef[] = [
    { field: "title", headerName: "Title", minWidth: 200, flex: 1 },
    // {
    //   field: "currency",
    //   headerName: "Currency",
    //   sortable: false,
    //   width: 200
    // },
    // { field: "shortDescription", headerName: "Description", sortable: false, minWidth: 300, flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 300,
      renderCell: (cellValues) => {
        return (
          <Stack direction="row" spacing={1}>
            <IconButton
              aria-label="edit"
              onClick={(e: any) => onEditHandle(e, cellValues)}
            >
              <span className="material-symbols-outlined">border_color</span>
            </IconButton>
            <IconButton
              aria-label="delete"
              onClick={(e: any) => dropdownHandleDelete(e, cellValues)}
            >
              <span className="material-symbols-outlined">delete</span>
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const onEditHandle = (event, data) => {
    event.stopPropagation();
    setState((prev) => ({ ...prev, open: true, details: data?.row }));
    setCaptureLead(data?.row?.captureLead);
  };

  const dropdownHandleDelete = (event, data) => {
    event.stopPropagation();
    setIsAlertOpen(true);
    setState((prev) => ({ ...prev, details: data?.row }));
  };

  useEffect(() => {
    getPrompts();
  }, [search]);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      isLoading: prompts["isLoading"],
    }));
  }, [prompts["isLoading"]]);

  const getPrompts = async () => {
    dispatch(
      getPromptsFromStore({
        filter: {
          search: search
        },
        range: {
          page,
          pageSize,
        }
      })
    );
    handleClose();
  };

  const handleClickOpen = (event) => {
    event.stopPropagation();
    setState((prev) => ({ ...prev, open: true }));
  };

  const handleClose = () => {
    setState((prev) => ({
      ...prev,
      open: false,
      details: null,
    }));
  };

  const handleDelete = async () => {
    try {
      updatePromptDeleting(true);
      if (!!details?.promptId) {
        const payload = {
          promptId: details?.promptId,
        };
        const response = await promptService.deletePrompt(payload);
        if (response?.data?.success) {
          updatePromptDeleting();
          toastSuccess(response?.data?.message);
          getPrompts();
          setState((prev) => ({ ...prev, details: null }));
          setIsAlertOpen(false);
        }
      }
    } catch (e) {
      updatePromptDeleting()
    }
  };

  const updatePromptDeleting = (isTrue = false) => {
    setState((prevState) => ({
      ...prevState,
      isPromptDeleting: isTrue,
    }));
    setIsAlertOpen(false);
  }

  const onModelChange = (event) => {
    setState((prevState) => ({
      ...prevState,
      page: event.page + 1,
      pageSize: event.pageSize,
    }));

    dispatch(
      getPromptsFromStore({
        range: {
          page: event.page + 1,
          pageSize: event.pageSize,
        },
      })
    );
  };

  const handleSearch = useCallback((value) => {
    setSearch(value)
  }, []);

  const debouncedHandleSearch = useDebounce(handleSearch, 300);

  const handleChange = (value) => {
    debouncedHandleSearch(value)
  };

  return (
    <>
      <div className="project_lists">
        <div className="container-fluid">
          <div className="chat_title">
            <h4 className="my-4">Prompt List</h4>
            <div>
              <TextField onChange={(e) => handleChange(e.target.value)} id="standard-basic" label="Filter by search" variant="standard" />
              <Button
                variant="outlined"
                className="chat_add_button addBtn"
                onClick={handleClickOpen}
              >
                <AddOutlinedIcon /> {"  "}New Prompt
              </Button>
            </div>
          </div>
          {prompts && (
            <div
              style={{
                height: "82vh",
                width: "100%",
                backgroundColor: "white",
              }}
            >
              <DataGrid
                style={{border: 0}}
                pagination
                paginationMode="server"
                rows={prompts["prompts"]}
                columns={columns}
                getRowId={(row) => row.promptId}
                loading={isLoading}
                onPaginationModelChange={onModelChange}
                rowCount={prompts["total"]}
                initialState={{
                  pagination: {
                    paginationModel: { pageSize },
                  },
                }}
                pageSizeOptions={[10, 20, 30, 40, 50]}
              />
            </div>
          )}
        </div>
      </div>
      {open && (
        <CreatePrompt
          open={open}
          handleClose={handleClose}
          getPrompt={getPrompts}
          detail={details}
          captureLeadValue={captureLead}
        />
      )}
      {isAlertOpen && (
        <DeleteConfirmation
          onCancel={() => {
            setIsAlertOpen(false);
            handleClose();
          }}
          isOpen={isAlertOpen}
          onAccept={handleDelete}
          onLoading={isPromptDeleting}
        />
      )}
    </>
  );
};

export default Prompts;
