import { useCallback, useEffect, useRef, useState } from "react";
import "./styles.css";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { Button, IconButton, Stack, TextField } from "@mui/material";
import { promptService } from "services";
import { UpdateGenericPrompt } from "./updateGenericPrompts";

type Props = {};

const initialState: any = {
  genericPromptList: [],
  listTotal: 0,
  open: false,
  details: false,
  page: 0,
  pageSize: 20,
  isLoading: false,
};

function useDebounce(func, delay) {
  const timeoutRef = useRef(null);

  return useCallback((...args) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      func(...args);
    }, delay);
  }, [func, delay]);
}


const GenericPrompts = (props: Props) => {
  const [
    { genericPromptList, listTotal, open, details, page, pageSize, isLoading },
    setState,
  ] = useState(initialState);
  const [search, setSearch] = useState("")

  const columns: GridColDef[] = [
    { field: "promptType", headerName: "Name", minWidth: 200, flex: 1 },
    { field: "prompt", headerName: "Content", minWidth: 200, flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 300,
      renderCell: (cellValues) => {
        return (
          <Stack direction="row" spacing={1}>
            <IconButton
              aria-label="edit"
              onClick={(e: any) => onEditHandle(e, cellValues)}
            >
              <span className="material-symbols-outlined">border_color</span>
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const handleLoading = (isLoading = false) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: isLoading,
    }));
  };

  const getGenericPromptList = async (page: number, pageSize: number) => {
    try {
      handleLoading(true);
      let payload = {
        filter: {
          genericPromptId: [],
          search: search
        },
        range: {
          page: page,
          pageSize: pageSize,
        },
        sort: {
          orderBy: "promptType",
          orderDir: "asc",
        },
      };

      const response = await promptService.genericPromptList(payload);
      if (response.data.success) {
        setState((prevState) => ({
          ...prevState,
          genericPromptList: response.data.data,
          listTotal: response.data.total,
        }));
        handleLoading();
        handleClose();
      }
    } catch (error) {
      handleLoading();
      handleClose();
    }
  };

  useEffect(() => {
    getGenericPromptList(page, pageSize);
  }, [search]);

  const onModelChange = (event) => {
    setState((prevState) => ({
      ...prevState,
      page: event.page + 1,
      pageSize: event.pageSize,
    }));
    getGenericPromptList(event.page + 1, event.pageSize);
  };

  const onEditHandle = (event, data) => {
    event.stopPropagation();
    setState((prevState) => ({
      ...prevState,
      open: true,
      details: data?.row,
    }));
  };

  const handleClose = () => {
    setState((prev) => ({
      ...prev,
      open: false,
      details: null,
    }));
  };

  const handleSearch = useCallback((value) => {
    setSearch(value)
  }, []);

  const debouncedHandleSearch = useDebounce(handleSearch, 300);

  const handleChange = (value) => {
    debouncedHandleSearch(value)
  };

  return (
    <>
      <div className="project_lists">
        <div className="container-fluid">
          <div className="chat_title">
            <h4 className="my-4">Generic Prompt List</h4>
            <TextField onChange={(e) => handleChange(e.target.value)} id="standard-basic" label="Filter by search" variant="standard" />
          </div>

          <div
            style={{
              height: "82vh",
              width: "100%",
              backgroundColor: "white",
            }}
          >
            <DataGrid
              style={{border: 0}}
              pagination
              paginationMode="server"
              rows={genericPromptList}
              columns={columns}
              getRowId={(row) => row.genericPromptId}
              loading={isLoading}
              onPaginationModelChange={onModelChange}
              rowCount={listTotal}
              initialState={{
                pagination: {
                  paginationModel: { page, pageSize },
                },
              }}
              pageSizeOptions={[10, 20, 30, 40, 50]}
            />
          </div>
        </div>
      </div>
      {open && (
        <UpdateGenericPrompt
          open={open}
          handleClose={handleClose}
          getGenericPrompt={() => getGenericPromptList(page + 1, pageSize)}
          detail={details}
        />
      )}
    </>
  );
};

export default GenericPrompts;
