import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import "./styles.css";

import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const InvalidLink = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.state === null) {
      navigate("/");
    }
  }, []);
  if (location.state) {
    return (
      <div className="signup">
        <div className="left_section_signup">
          <div className="verificationContainer">
            <WarningAmberIcon sx={{ fontSize: "3rem", color: "var(--color-btn-orange)" }} />
            <h3>Invalid Link</h3>
            <p>Sorry, the link you've visited is invalid or broken.</p>
          </div>
        </div>
      </div>
    );
  }
};

export default InvalidLink;
