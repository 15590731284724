import React, { Component } from "react";
import styles from "./input.module.css";
import {
  Input as MuInput
} from "@mui/material";
interface Props {
  placeholder?: string;
  onChange?: (name: any) => void;
  disabled?: boolean;
  value?: string;
  type?: InputType;
  name?: string;
  defaultValue?: string;
  register?: any;
  required?: string;
  pattern?: object;
  maxRows?: number;
  minRows?: number;
  multiline?: boolean;
  minLength?: any;
  isLogin?: boolean;
}

type InputType = "text" | "email" | "password";

export class Input extends Component<Props, {}> {
  // private onChange(event) {
  //     this.props.onChange(event.target.value)
  // }

  public render() {
    const {
      placeholder,
      disabled,
      value,
      type,
      name,
      register,
      required,
      minLength,
      onChange = () => null,
      pattern,
      maxRows = 1,
      minRows = 1,
      multiline = false,
      isLogin = false
    } = this.props;

    return (
      <div className={styles.root}>
        {isLogin ? (
          <input 
            className="form-control"
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            disabled={disabled}
            {...register(name, { required, minLength })}
            type={type}
            autoComplete="new-password"
          />
        ) : (
          <MuInput
            // className={styles.input}
            className="form-control"
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            disabled={disabled}
            {...register(name, { required, minLength })}
            type={type}
            maxRows={maxRows}
            minRows={minRows}
            multiline={multiline}
            autoComplete="new-password"
          />
        )}
      </div>
    );
  }
}
