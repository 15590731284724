import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  clearAll,
  getToken,
  getUser,
  setToken,
  setUser,
  getFromStore,
  setToStorage,
  storageKey
} from "helpers/storage";
import { RootState, useAppDispatch } from "redux/store";
import BaseService from "services/Base";
import { Auth } from "types/Auth";
import { getSubscriptionInfoStore } from "./clientSlice";
import IntegrationService from "services/Integration";

type AppState = {
  user: Auth.UserProfile | null;
  token?: string | null;
  isLoading?: boolean;
  isLoaded?: boolean;
  isShowSuperAdminButton?: boolean;
};

type UserParams = {
  user: any;
  token: string;
  dispatch: any;
  isSetToken: boolean;
}

export const getUserFromStore = createAsyncThunk(
  "auth/getUserFromStore",
  async (params: UserParams) => {
    const user = !!params?.user ? params?.user : await getUser();
    const token = !!params?.token ? params?.token : await getToken();
    // const subscription = !!params?.token ? params?.token : await getFromStore(storageKey.subscription);
    if(params?.isSetToken){
      setToken(params?.token);
      setUser(params?.user);
      if(!!params?.user?.subscriptionDetails) setToStorage(storageKey.subscription, params?.user?.subscriptionDetails);
    }
    if(token){
      await BaseService.setAuthToken(token?.toString());
      await IntegrationService.setAuthToken(token?.toString());
      if(user && user?.roleId != 1 && !!params?.dispatch) await params?.dispatch(getSubscriptionInfoStore());
    } 
    return { user, token };
  }
);

export const logoutStore = createAsyncThunk("auth/logoutStore", async () => {
  return await clearAll();
});

const initialState: AppState = {
  user: null,
  token: null,
  isLoading: false,
  isLoaded: false,
  isShowSuperAdminButton: false,
};

export const appStateSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    setAppState: (state, action: PayloadAction<any>) => {
      const { user, token } = action.payload;
      state.user = user;

      state.token = token;
      BaseService.setAuthToken(token);
      IntegrationService.setAuthToken(token);
      setToken(token);
      setUser(user);
    },
    updateSuperAdminState: (state, action: PayloadAction<any>) => {
      state.isShowSuperAdminButton = action?.payload?.isShow;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = false;
      })
      .addCase(getUserFromStore.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.token = action.payload?.token;
        // BaseService.setAuthToken(state.token);
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(getUserFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
      });
    builder
      .addCase(logoutStore.fulfilled, (state, action) => {
        state.user = null;
        state.token = null;
      })
      .addCase(logoutStore.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const { setAppState, updateSuperAdminState } = appStateSlice.actions;

export default appStateSlice.reducer;
export const selectCurrentUser = (state: RootState) => state.appState.user;
export const selectCurrentToken = (state: RootState) => state.appState.token;
export const selectIsLoading = (state: RootState) => state.appState.isLoading;
export const selectIsLoaded = (state: RootState) => state.appState.isLoaded;
export const selectIsShowSuperAdminButton = (state: RootState) =>
  state.appState.isShowSuperAdminButton;
