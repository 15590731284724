import { get } from "lodash";
import BaseService from "./Base";
import ApiUrls from "./ApiUrl";
import { toastError } from "helpers/toastHelper";
import { ProjectBodyParam } from "types/Projects";

export default class ClientService extends BaseService {
  async getClients(param: ProjectBodyParam = {}) {
    try {
      const response = await this.post(ApiUrls.clientList, param);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  async deleteClient(params = {}) {
    try {
      const response = await this.post(ApiUrls.clientDelete, params);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async createClient(params = {}) {
    try {
      const response = await this.post(ApiUrls.clientCreate, params);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async updateClient(params = {}) {
    try {
      const response = await this.post(ApiUrls.clientUpdate, params);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async checkUserSubscription() {
    try {
      const response = await this.post(`${ApiUrls.checkUserSubscription}`, {});
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  async getTransactionDetails() {
    try {
      const response = await this.post(`${ApiUrls.getTransactionDetails}`, {});
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  async getInvoiceDetails() {
    try {
      const response = await this.post(`${ApiUrls.getInvoiceDetails}`, {});
      return response?.data;
    } catch (error) {
      throw error;
    }
  }


  async getTemplateList(param = {}) {
    try {
      const response = await this.post(`${ApiUrls.templateList}`, param);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  async createNewProject(param = {}) {
    try {
      const response = await this.post(`${ApiUrls.createTemplate}`, param);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

}
