import { useRoutes } from 'react-router-dom';

// project import
import LoginRoutes from './AuthRoutes';
import MainRoutes from './MainRoutes';
import { getToken, getUser } from 'helpers/storage';
import { useEffect, useState } from 'react';
import FullPageRoutes from './fullPageRoutes';

export default function ThemeRoutes() {
  const [routes, setRoutes] = useState<any[]>([LoginRoutes, MainRoutes, FullPageRoutes]);

  useEffect(()=> {
    const token = getToken();
    if(!!token){
      setRoutes([MainRoutes, LoginRoutes, FullPageRoutes]);
    }
  }, []);

  return useRoutes(routes);
}