import { clearAll, getToken, getUser, setToken } from "../helpers/storage";
import axios, { AxiosInstance } from "axios";
import { get } from "lodash";
import ApiUrls from "./ApiUrl";
import { toastError } from "helpers/toastHelper";

export enum Header {
  Authorization = "authorization",
  ProfileId = "x-profile-id",
}

class IntegrationService {
  protected axios: AxiosInstance;
  public static authToken: string;
  public static profileId: number;
  public abortTime: number =
    Number(process.env.REACT_APP_API_TIMEOUT_ABORT_TIME) || 5000;

  constructor(private baseURL = process.env.REACT_APP_API_BASE_URL) {
    const http = axios.create({
      timeout: 10 * 1000,
      baseURL,
    });
    http.interceptors.response.use(
      (response) => response,
      async (error) => {
        let status = error?.response?.status;
        if (status == 403 || status == 401) {
          clearAll();
          window.location.reload();
        } else if (status == 422 || status == 400) {
          let errorMsg = "Something went wrong!";
          const msg = error.response?.data?.message;
          if (msg) {
            errorMsg = msg;
          }
          if (msg != "No active subscription plan found.") toastError(errorMsg);
        } else if (error?.code === "ERR_CANCELED") {
          // Handle API Pending
        }
        return Promise.reject(error);
      }
    );
    this.axios = http;
  }

  public refreshToken = async () => {
    const response = await this.post(ApiUrls.refreshToken, {});
    if (response?.data) {
      setToken(response?.data?.token);
      return IntegrationService.setAuthToken(response?.data?.token);
    }
  };

  public static getIntegrationUrl = () => {
    return process.env.REACT_APP_API_BASE_URL;
  };
  public static getTimeOut = () => {
    return process.env.API_TIMEOUT_ABORT_TIME;
  };
  public getInstance = (): AxiosInstance => {
    return axios.create({
      timeout: 180 * 1000,
      baseURL: this.baseURL,
      headers: {
        authorization: IntegrationService.authToken,
        "x-profile-id": IntegrationService.profileId
      },
    });
  };

  protected setHeaderFromParams(params: any) {
    const headers: any = {
      // "Content-Type": "application/json",
    };
    headers[Header.Authorization] = `Bearer ${IntegrationService.authToken}`;
    // headers[Header.ProfileId] = IntegrationService.profileId

    const res: any = {};
    res.headers = { ...headers, ...(params.headers || {}) };
    delete params.headers;
    if (params.responseType) {
      res.responseType = params.responseType;
    }
    if (params.signal) {
      res.signal = params.signal;
      delete params.signal;
    }
    res.params = params;
    return res;
  }

  public static setAuthToken(token: string) {
    IntegrationService.authToken = token;
  }

  // public static setProfileId(profileId: number) {
  //     IntegrationService.profileId = profileId
  // }
  newAbortSignal(timeoutMs = this.abortTime || 5000) {
    const abortController = new AbortController();
    setTimeout(() => abortController.abort(), timeoutMs || 0);
    return abortController.signal;
  }

  async get(url: string, params = {}) {
    params["signal"] = this.newAbortSignal(this.abortTime);
    const config = this.setHeaderFromParams(params);
    const response = await this.axios.get(url, config);
    return response;
  }

  async post(url: string, data: any, params = {}) {
    params["signal"] = this.newAbortSignal(this.abortTime);
    const config = this.setHeaderFromParams(params);
    const response = await this.axios.post(url, data, config);
    return response;
  }

  async put(url: string, data: any, params = {}) {
    params["signal"] = this.newAbortSignal(this.abortTime);
    const config = this.setHeaderFromParams(params);
    const response = await this.axios.put(url, data, config);
    return response;
  }

  async delete(url: string, params = {}) {
    params["signal"] = this.newAbortSignal(this.abortTime);
    const config = this.setHeaderFromParams(params);
    const response = await this.axios.delete(url, config);
    return response;
  }
}

export default IntegrationService;
