const ApiUrls = {
  //Auth APIs
  loginAs: "/v1/auth/login-as",
  login: "/v1/auth/sign-in",
  sendOTP: "/v1/auth/send-otp",
  resetPassword: "/v1/auth/reset-password",
  verifyPassword: "/v1/auth/verify-otp",
  refreshToken: "/v1/auth/refresh-token",
  register: "/v1/auth/register",
  googleSignIn: "v1/google/sign-in",
  resendVerification: "v1/auth/resend-verification-link",
  linkValidation: "/v1/auth/link-validation",
  
  //Project APIs
  projectList: "/v1/client-project/list",
  projectCreate: "/v1/client-project/create",
  projectUpdate: "/v1/client-project/update",
  projectDelete: "/v1/client-project/delete",

  // Chatbot APIs
  categoryList: "/v1/category/list",
  clientPrompt: "/v1/client-prompt",

  chatbotList: "/v1/client-prompt/list",
  chatbotCreate: "/v1/client-prompt/create",
  chatbotDelete: "/v1/client-prompt/delete",
  getEmbedPrompts: "/v1/client-prompt/get-embed",
  updateClientPrompt: "/v1/client-prompt/update",
  uploadFile: "/v1/upload/file",
  clientPromptEmbeded: "/v1/client-prompt-embedding",
  countToken: "/v1/client-prompt-embedding/count-token",
  clientPromptEmbeddedMapping: "/v1/client-prompt-embed-mapping",
  lead: "/v1/lead",
  conversation: "/v1/conversations",
  quotaDetails: "/v1/report/current-quota",
  cloneBot: "/v1/client-prompt/clone-bot",

  // Clients API
  clientList: "/v1/client/list",
  clientCreate: "/v1/client/create",
  clientUpdate: "/v1/client/update",
  clientDelete: "/v1/client/delete",
  templateList: "/v1/template/list",
  createTemplate: "/v1/template/use",
  languageOptions: "/v1/language/list",
  promptList: "/v1/prompt/list",
  promptCreate: "/v1/prompt/create",
  promptUpdate: "/v1/prompt/update",
  promptDelete: "/v1/prompt/delete",
  genericPrompts: "/v1/generic-prompt",
  pricingFeature: "/v1/package-feature",
  pricing: "/v1/package",
  leadStatus: "/v1/lead-status",
  questionAnswer: "/v1/question-answer",
  report: "/v1/report",
  validationType: "/v1/validation-type/list",
  customValidationParameter: "/v1/custom-validation-parameter/list",
  checkUserSubscription: "/v1/user/get-me",
  parameterInputType: "/v1/parameter-input-type/list",
  conversationsDownload: "/v1/conversations/chat-text-file",
  createOrder: "/v1/client-order",
  createClintSubscription: "/v1/client-subscription",
  getTransactionDetails: "/v1/transaction/recent-transaction",
  getInvoiceDetails: "/v1/invoice/list",

  // Create User and Roles
  role: "/v1/role/",
  user: "/v1/user/",
  activeSwitch: "#",
  changePassword: "/v1/auth/change-password",

  // integration ms apis
  webHook: "/v1/client-prompt-action",
  // webHookIntegration: "/v1/integration/client-prompt-action",
  // webHookPlatform: "/v1/integration/web-hook/platform",
  // testIntegration: "/v1/integration/client-prompt-action/test-integration"
  webHookIntegration: "/v1/client-prompt-action",
  webHookPlatform: "/v1/web-hook/platform",
  testIntegration: "/v1/client-prompt-action/test-integration"
};

export default ApiUrls;
