import React, { useEffect, useMemo, useState } from "react";
import { inboxService } from "services";
import { toastSuccess,toastError } from "helpers/toastHelper";
import { ChatbotList } from "types/Chatbot";
import {
  getChatbotFromStore,
  selectChatbots,
  selectQuotaDetails,
} from "redux/features/chatbotSlice";
import { useAppDispatch, useTypedSelector } from "redux/store";
import "./inboxStyles.css";
import { styled } from "@mui/material/styles";
import DeleteConfirmation from "components/deleteConfirmation";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { Button, IconButton, Stack, Tooltip } from "@mui/material";
import * as XLSX from "xlsx";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { debounce } from "lodash";
import { textCapitalize, getFormatedDateWithTime } from "helpers/common";
import {
  getLeadStatusFromStore,
  selectLeadStatus,
} from "redux/features/inboxSlice";
import DeleteIcon from "@mui/icons-material/Delete";
let exlIcon = require("../../../src/assets/images/excel.png");

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const initialState = {
  page: 0,
  pageSize: 20,
  total: 0,
  clientPromptId: null,
  leadList: [],
  updatedElements: {},
  selectedLeadData: [],
  openModal: false,
  leadData: {},
  userDisplayName: null,
  searchValue: null,
  leadCreatedAt: null,
  filterLeadStatus: null,
  isActive: false,
  selectedLeadStatus: null,
  isLeadStatusEdit: false,
  chatbotInteractionId: null,
  isListLoading: false,
  isSubmitLoading: false,
  isCallbackRequired: null,
  webhookStatus: null,
  open: false,
  isDeletingLoading: false
};

const Inbox = () => {
  const columns: GridColDef[] = [
    {
      field: "userDisplayName",
      headerName: "Lead Name",
      minWidth: 200,
      cellClassName: "capitalize",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Lead Created On",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "isCallbackRequired",
      headerName: "Callback Required",
      minWidth: 200,
      flex: 1,
      cellClassName: "capitalize",
      valueFormatter: (params) => callbackRequiredHandle(params?.value),
    },
    {
      field: "webhookStatus",
      headerName: "Leads Synced with Integration",
      minWidth: 200,
      flex: 1,
      cellClassName: "capitalize",
      valueFormatter: (params) => params.value ? 'Yes':'No'
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <Stack direction="row" spacing={1}>
            <Tooltip title="View" placement="top">
              <IconButton
                aria-label="view"
                color="primary"
                onClick={(e: any) => viewInbox(e, cellValues)}
              >
                <RemoveRedEyeIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
            <IconButton
              color="error"
              aria-label="Delete"
              onClick={(e) => deletePopupOpen(e, cellValues)}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>

          </Stack>
        );
      },
    },
  ];

  const quotaDetails = useTypedSelector(selectQuotaDetails);

  const [
    {
      clientPromptId,
      openModal,
      page,
      pageSize,
      total,
      leadList,
      leadData,
      userDisplayName,
      searchValue,
      leadCreatedAt,
      filterLeadStatus,
      isActive,
      selectedLeadStatus,
      isLeadStatusEdit,
      chatbotInteractionId,
      isListLoading,
      isSubmitLoading,
      isCallbackRequired,
      webhookStatus,
      open,
      isDeletingLoading
    },
    setState,
  ] = useState(initialState);
  const chatbots: Array<ChatbotList> = useTypedSelector(selectChatbots);
  const dispatch = useAppDispatch();
  const leadStatus: Array<any> = useTypedSelector(selectLeadStatus);

  useEffect(() => {
    if (leadList.length > 0) {
      getChatbotHandler();
      // getLeadStatus();
    }
  }, [leadList.length > 0]);

  const callbackRequiredHandle = (id) => (id == true ? "Yes" : "No");

  const getLeadList = async (_page = page, _pageSize = pageSize) => {
    try {
      loaderHandler(true);

      let payload = {
        filter: {},
        range: {},
      };

      payload.range["page"] = _page || 1;
      payload.range["pageSize"] = _pageSize || 20;

      if (!!clientPromptId) {
        payload.filter["clientPromptId"] = clientPromptId;
      }
      if (!!filterLeadStatus) {
        payload.filter["leadStatus"] = filterLeadStatus;
      }
      if (!!leadCreatedAt) {
        payload.filter["createdAt"] = leadCreatedAt;
      }
      if (!!searchValue) {
        payload.filter["search"] = searchValue;
      }
      if (
        isCallbackRequired != null &&
        isCallbackRequired != "" &&
        isCallbackRequired != undefined
      ) {
        payload.filter["isCallbackRequired"] =
          isCallbackRequired == 1 ? true : false;
      }
      if (
        webhookStatus != null &&
        webhookStatus != "" &&
        webhookStatus != undefined
      ) {
        payload.filter["webhookStatus"] =
          webhookStatus == 1 ? true : false;
      }
      const response = await inboxService.leadList(payload);
      if (response["data"].success) {
        setState((prevState) => ({
          ...prevState,
          isListLoading: false,
          total: response["data"]?.total,
        }));
        let data = response["data"]?.data.map((item: any) => ({
          ...item,
          createdAt: getFormatedDateWithTime(item?.createdAt),
        }));

        // let pendingList = [];
        // let contactedList = [];
        // let completedList = [];

        // data.forEach((item) => {
        //   if (item.leadData != null) {
        //     if (item.leadStatus == "pending") {
        //       pendingList.push(item);
        //     } else if (item.leadStatus == "contacted") {
        //       contactedList.push(item);
        //     } else if (item.leadStatus == "completed") {
        //       completedList.push(item);
        //     }
        //   }
        // });

        setState((prevState) => ({
          ...prevState,
          leadList: data,
          // updatedElements: {
          //   pending: pendingList || [],
          //   contacted: contactedList || [],
          //   completed: completedList || [],
          // },
        }));
      }
    } catch (error) {
      loaderHandler();
    }
  };

  const loaderHandler = (isTrue = false) => {
    setState((prevState) => ({
      ...prevState,
      isListLoading: isTrue,
    }));
  };

  useMemo(() => {
    if (
      (!clientPromptId && !filterLeadStatus && !leadCreatedAt) ||
      searchValue
    ) {
      getLeadList();
    }
  }, [clientPromptId, filterLeadStatus, leadCreatedAt, searchValue]);

  const getLeadStatus = async () => {
    dispatch(getLeadStatusFromStore({}));
    handleClose();
  };

  const getChatbotHandler = () => {
    const payload = {};
    dispatch(getChatbotFromStore(payload));
  };

  const handleClientPromptChange = (value) => {
    setState((prevState) => ({
      ...prevState,
      clientPromptId: value,
    }));
  };

  const handleLeadStatusChange = (value) => {
    setState((prevState) => ({
      ...prevState,
      filterLeadStatus: value,
    }));
  };

  const handleCallbackChange = (value) => {
    setState((prevState) => ({
      ...prevState,
      isCallbackRequired: value,
    }));
  };

  const handleLeadSyncedChange = (value) => {
    setState((prevState) => ({
      ...prevState,
      webhookStatus: value,
    }));
  };

  const debouncedHandleInputChange = debounce((value) => {
    setState((prevState) => ({
      ...prevState,
      searchValue: value,
    }));
  }, 1000);

  const handleSearch = (event) => {
    let { value } = event.target;
    debouncedHandleInputChange(value);
  };

  const getCreatedAt = (event) => {
    let { value } = event.target;
    setState((prevState) => ({
      ...prevState,
      leadCreatedAt: value,
    }));
  };

  // const removeFromList = (list, index) => {
  //   const result = Array.from(list);
  //   const [removed] = result.splice(index, 1);
  //   return [removed, result];
  // };

  // const addToList = (list, index, element) => {
  //   const result = Array.from(list);
  //   result.splice(index, 0, element);
  //   return result;
  // };

  // const onDragEnd = async (result) => {
  //   if (!result.destination) return;

  //   const listCopy = { ...updatedElements };
  //   const sourceList = listCopy[result.source.droppableId];

  //   const [removedElement, newSourceList] = removeFromList(
  //     sourceList,
  //     result.source.index
  //   );
  //   listCopy[result.source.droppableId] = newSourceList;
  //   const destinationList = listCopy[result.destination.droppableId];
  //   listCopy[result.destination.droppableId] = addToList(
  //     destinationList,
  //     result.destination.index,
  //     removedElement
  //   );

  //   setState((prevState) => ({
  //     ...prevState,
  //     updatedElements: listCopy,
  //   }));

  //   let selectedLead = [];
  //   sourceList.forEach((item) => {
  //     if (item.socketId == result.draggableId) {
  //       selectedLead.push(item);
  //     }
  //   });

  //   if (selectedLead.length > 0) {
  //     try {
  //       let payload = {
  //         chatbotInteractionId: selectedLead[0]?.chatbotInteractionId,
  //         leadStatus: result.destination.droppableId,
  //       };
  //       const response = await chatbotService.updateLead(payload);
  //       if (response?.data?.success) {
  //         getLeadList();
  //       }
  //     } catch (error) {
  //       toastError(error["response"]?.data?.message ?? "Something went wrong!");
  //     }
  //   }
  // };

  // const handleClose = () => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     openModal: false,
  //   }));
  // };

  const deletePopupOpen = (e, cellValues) => {
    e.stopPropagation();
    setState((prev) => ({
      ...prev,
      open: true,
      chatbotInteractionId: cellValues['row']?.chatbotInteractionId
    }));
  };

  const handleDelete = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isDeletingLoading: true,
      }));
      if (!!chatbotInteractionId) {
        let payload = {
          chatbotInteractionId: [chatbotInteractionId]
        };
        const response = await inboxService.deleteLead(
          payload
        );
        if (response?.data?.success) {
          resetLoading()
          toastSuccess(response?.data?.message);
          getLeadList()
        }
      }
    } catch (e) {
      resetLoading()
      toastError(e["response"]?.data?.message);
    }
  };

  const resetLoading = () => {
    setState((prevState) => ({
      ...prevState,
      open: false,
      isDeletingLoading: false,
    }));
  }



  const viewInbox = (event, cellValue) => {
    event.stopPropagation();
    setState((prevState) => ({
      ...prevState,
      openModal: true,
      leadData: cellValue?.row?.leadData || "",
      userDisplayName: cellValue?.row?.userDisplayName || "",
      selectedLeadStatus: cellValue?.row?.leadStatus || "",
      chatbotInteractionId: cellValue?.row?.chatbotInteractionId || "",
      isCallbackRequired: callbackRequiredHandle(
        cellValue?.row?.isCallbackRequired
      ),
    }));
  };

  const saveExcel = async () => {
    let fileList = [];
    for (let i = 0; i < leadList.length; i++) {
      leadList?.map((item: any) => ({
        ...item,
        createdAt: getFormatedDateWithTime(item?.createdAt),
      }));
      let data = {
        "Lead Name": textCapitalize(leadList[i]?.userDisplayName),
        "Lead Created On": leadList[i]?.createdAt,
        "Callback Required": callbackRequiredHandle(
          leadList[i]?.isCallbackRequired
        ),
        "Leads Synced with Integration": leadList[i]?.webhookStatus ? "Yes":"No"
      };
      fileList.push(data);
    }

    const worksheet = XLSX.utils.json_to_sheet(fileList);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "lead_data.xlsx");
  };

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      openModal: false,
      isLeadStatusEdit: false,
    }));
  };

  const handleFilterBox = () => {
    setState((prevState) => ({
      ...prevState,
      isActive: !isActive,
    }));
  };

  const getFilterData = () => {
    getLeadList();
    setState((prevState) => ({
      ...prevState,
      isActive: false,
    }));
  };

  const getClearData = async () => {
    setState((prevState) => ({
      ...prevState,
      clientPromptId: "",
      filterLeadStatus: "",
      leadCreatedAt: "",
      isCallbackRequired: "",
      webhookStatus: "",
    }));
  };

  const onEditLeadStatus = () => {
    setState((prevState) => ({
      ...prevState,
      isLeadStatusEdit: true,
    }));
  };

  const onClearStatusEditForm = () => {
    setState((prevState) => ({
      ...prevState,
      isLeadStatusEdit: false,
    }));
  };

  const editLeadStatus = async (event) => {
    setState((prevState) => ({
      ...prevState,
      selectedLeadStatus: event.target.value,
    }));
  };

  const onSubmitStatusEditForm = async () => {
    try {
      updateLoader(true);
      if (!!selectedLeadStatus && !!chatbotInteractionId) {
        let newSelectedLeadStatus =
          selectedLeadStatus?.charAt(0)?.toUpperCase() +
          selectedLeadStatus?.slice(1);
        let payload = {
          chatbotInteractionId: chatbotInteractionId,
          leadStatus: newSelectedLeadStatus,
        };
        const response = await inboxService.updateLead(payload);
        if (response?.data?.success) {
          setState((prevState) => ({
            ...prevState,
            isSubmitLoading: false,
          }));
          toastSuccess(
            response?.data?.message ?? "Lead status updated successfully"
          );
          updateLoader();
          getLeadList();
        }
      }
    } catch (error) {
      updateLoader();
    }
  };

  const updateLoader = (isShow = false) => {
    setState((prevState) => ({
      ...prevState,
      isSubmitLoading: isShow,
    }));
  };

  const onModelChange = (event) => {
    setState((prevState) => ({
      ...prevState,
      page: event.page + 1,
      pageSize: event.pageSize,
    }));

    getLeadList(event.page + 1, event.pageSize);
  };

  return (
    <>
      <div className="project_lists" style={{marginTop: `${quotaDetails?.data?.isQuestionQuotaToBeExpired && "2rem"}`}}>
        <div className="container-fluid">
          <h4 className="my-4 mx-2">Lead List</h4>
          <div className="mt-4 p-3 content-form-card">
            <div className="filterIcon">
              <div className="searchBarWidth">
                <input
                  type="text"
                  onChange={handleSearch}
                  className="form-control"
                  placeholder="Search Lead Name"
                />
              </div>
              <Button variant="outlined" onClick={saveExcel}>
                <img className="txtIconSize" src={exlIcon} />
              </Button>
            </div>

            {/* {isActive && ( */}
            {/* <Box sx={{ minWidth: 275, marginBottom: 2, boxShadow: 1, marginTop: 2}}> */}
            <div className="row">
              <div className="col-sm-12 col-md-3 mt-3 mb-3">
                <label className="mb-1">Chatbot</label>
                <select
                  name=""
                  className="form-select inbox-seelct-dropdown capitalize"
                  onChange={(e) => handleClientPromptChange(e.target.value)}
                  value={clientPromptId || ""}
                >
                  <option value="">Select Chatbot</option>
                  {!!chatbots && chatbots["chatbots"]?.length > 0 ? (
                    chatbots["chatbots"]?.map((option) => (
                      <option
                        key={option?.clientPromptId}
                        value={option.clientPromptId}
                        className="capitalize"
                      >
                        {option.name}
                      </option>
                    ))
                  ) : (
                    <option>No Data Found</option>
                  )}
                </select>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-3 mt-3 mb-3">
                <label className="mb-1">Callback Required</label>
                <select
                  name=""
                  className="form-select inbox-seelct-dropdown capitalize"
                  onChange={(e) => handleCallbackChange(e.target.value)}
                  value={isCallbackRequired || ""}
                >
                  <option value="">Select Callback Required</option>
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                  {/* {leadStatus?.length > 0 ? (
                    leadStatus?.map((option) => (
                      <option key={option?.leadStatusId} value={option.name}>
                        {option.name}
                      </option>
                    ))
                  ) : (
                    <option>No Data Found</option>
                  )} */}
                </select>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-3 mt-3 mb-3">
                <label className="mb-1">Lead Synced with Integration</label>
                <select
                  name=""
                  className="form-select inbox-seelct-dropdown capitalize"
                  onChange={(e) => handleLeadSyncedChange(e.target.value)}
                  value={webhookStatus || ""}
                >
                  <option value="">Select Lead Sync Status</option>
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-3 mt-3 mb-3">
                <label className="mb-1">Lead Created On</label>
                <input
                  type="date"
                  className="form-control"
                  onChange={getCreatedAt}
                  value={leadCreatedAt || ""}
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-3 mt-3 mb-3 leadActionBtn">
                <Button
                  variant="outlined"
                  type="button"
                  className="clear_button"
                  onClick={getClearData}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  type="button"
                  className="apply_button"
                  onClick={getFilterData}
                >
                  Apply
                </Button>
              </div>
            </div>

            {/* <DragDropContext onDragEnd={onDragEnd}>
              <div className="row">
                {leadStatusList &&
                  leadStatusList.map((list, i) => (
                    <div
                      key={`lead_status_${i}`}
                      className="col-lg-3 columnWidth"
                    >
                      <Card sx={{ bgcolor: "#f5f5f5" }}>
                        <CardContent>
                          <div className="lead_header">
                            <div>
                              <h5 className="mb-0">{list}</h5>
                            </div>
                          </div>
                          <Droppable droppableId={list} key={i}>
                            {(provided) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                {updatedElements[list] &&
                                  updatedElements[list]?.map((data, index) => (
                                    <Draggable
                                      draggableId={data?.socketId}
                                      index={index}
                                      key={data?.socketId}
                                    >
                                      {(provided, snapshot) => {
                                        return (
                                          <div
                                            ref={provided.innerRef}
                                            snapshot={snapshot}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          >
                                            <Box
                                              sx={{
                                                boxShadow: 1,
                                                width: "330px",
                                                height: "auto",
                                                bgcolor: (theme) =>
                                                  theme.palette.mode === "dark"
                                                    ? "#101010"
                                                    : "#fff",
                                                color: (theme) =>
                                                  theme.palette.mode === "dark"
                                                    ? "grey.300"
                                                    : "grey.800",
                                                p: 1,
                                                m: 1,
                                                borderRadius: 2,
                                                textAlign: "center",
                                                fontSize: "0.875rem",
                                                fontWeight: "700",
                                              }}
                                            >
                                              <div className="lead-content">
                                                <div>
                                                  <h5>
                                                    {data.userDisplayName}
                                                  </h5>
                                                </div>
                                              </div>
                                              <hr className="new_line" />
                                              <div className="row mt-1">
                                                {data?.leadData &&
                                                  Object.entries(
                                                    data?.leadData
                                                  ).map(
                                                    (
                                                      [key, value]: any,
                                                      index
                                                    ) => (
                                                      <div
                                                        key={`lead_data_${index}`}
                                                        className="col-6 mt-3"
                                                      >
                                                        <h6 className="textStyle">
                                                          {key.replace(
                                                            /[0-9]./g,
                                                            ""
                                                          )}
                                                        </h6>
                                                        <h6 className="titleFont">
                                                          {value}
                                                        </h6>
                                                      </div>
                                                    )
                                                  )}
                                              </div>
                                            </Box>
                                          </div>
                                        );
                                      }}
                                    </Draggable>
                                  ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </CardContent>
                      </Card>
                    </div>
                  ))}
              </div>
            </DragDropContext> */}

            <div
              style={{
                height: "63vh",
                width: "100%",
                backgroundColor: "white",
              }}
            >
              <DataGrid
                style={{border: 0}}
                pagination
                paginationMode="server"
                rows={leadList}
                columns={columns}
                getRowId={(row) =>
                  `${row.socketId}_${Math.floor(Math.random() * 100000000)}`
                }
                loading={isListLoading}
                onPaginationModelChange={onModelChange}
                rowCount={Number(total) || 0}
                initialState={{
                  pagination: {
                    paginationModel: { page, pageSize },
                  },
                }}
                pageSizeOptions={[10, 20, 30, 40, 50]}
              />
            </div>
          </div>
        </div>
      </div>


      {open && (
        <DeleteConfirmation
          onCancel={() => setState((prev) => ({ ...prev, open: false }))}
          isOpen={open}
          onAccept={handleDelete}
          onLoading={isDeletingLoading}
          title="Deleting this will also delete the linked conversation"
          description="Are you sure you want to delete this entry?"
        />
      )}


      {openModal && (
        <BootstrapDialog
          aria-labelledby="customized-dialog-title"
          onClose={() => handleClose()}
          open={openModal}
        >
          <BootstrapDialogTitle onClose={() => handleClose()}>
            <span className="capitalize">{userDisplayName}</span>
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <div className="row">
              {leadData &&
                Object.entries(leadData)?.map(
                  ([key, value]: any, index: number) => (
                    <div key={`${key}_${index}`} className="col-6 mt-2">
                      {/\d/.test(key) ? (
                        <h6 className="keyText">
                          {key
                            .replace(/[0-9]./g, "")
                            .charAt(1)
                            .toUpperCase() +
                            key.replace(/[0-9]./g, "").slice(2)}
                        </h6>
                      ) : (
                        <h6 className="keyText capitalize">{key}</h6>
                      )}
                      <h6 className="valueText">{value}</h6>
                    </div>
                  )
                )}
            </div>
            <div className="row mt-2">
              <h6 className="keyText">Callback Required</h6>
              <h6 className="valueText">{isCallbackRequired}</h6>
              {/* <div>{callbackRequiredHandle(leadList[i]?.isCallbackRequired)}</div> */}
            </div>
            {/* <div className="row mt-2">
              <h6 className="keyText"> Lead Status</h6>
              <div className="row">
                <div className="col-10">
                  {!isLeadStatusEdit && (
                    <h6 className="valueText">
                      {selectedLeadStatus} &nbsp;{" "}
                      <EditIcon onClick={onEditLeadStatus} />
                    </h6>
                  )}
                  {isLeadStatusEdit && (
                    <select
                      name=""
                      className="form-select inbox-seelct-dropdown capitalize"
                      onChange={(e) => editLeadStatus(e)}
                      value={selectedLeadStatus || ""}
                    >
                      {leadStatus?.length ? (
                        leadStatus?.map((option) => (
                          <option
                            key={option?.leadStatusId}
                            value={option.name || ""}
                          >
                            {option.name}
                          </option>
                        ))
                      ) : (
                        <option>No Data Found</option>
                      )}
                    </select>
                  )}
                </div>
              </div>
              {isLeadStatusEdit && (
                <div className="statusSubmitBtn mt-3">
                  <Button
                    variant="outlined"
                    className="leadBtn"
                    onClick={onClearStatusEditForm}
                  >
                    Cancel
                  </Button>
                  <Button variant="contained" onClick={onSubmitStatusEditForm}>
                    {isSubmitLoading ? (
                      <CircularProgress color="inherit" size={"1.5rem"} />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </div>
              )}
            </div> */}
          </DialogContent>
        </BootstrapDialog>
      )}

      {/* {openModal && (
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={openModal}
        >
          <BootstrapDialogTitle onClose={handleClose}>
            <h6>PLEASE CONFIRM CHANGING STATUS OF THE LEAD</h6>
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <form className="add-project">
              <div className="row">
                <div className="form-group mb-4 col-lg-12">
                  <div className="lead_detail">
                    <div className="detail">
                      <div className="idColor">{leadId}</div>
                      <div>{leadTitle}</div>
                    </div>
                  </div>
                </div>
                <div className="form-group mb-4 col-lg-12">
                  <label htmlFor="">From</label>
                  <input
                    type="text"
                    className="form-control"
                    value={leadStatus}
                    disabled
                  />
                </div>
                <div className="form-group mb-4 col-lg-12">
                  <label>To</label>
                  <select className="form-select" onChange={handleLeadStatus}>
                    <option value="Pending">Pending</option>
                    <option value="Application Complete">
                      Application Complete
                    </option>
                    <option value="Web Lead">Web Lead</option>
                  </select>
                </div>
              </div>
            </form>
            <DialogActions>
              <Button
                autoFocus
                className="save-btn mx-auto"
                onClick={handleConfirm}
              >
                Confirm
              </Button>
            </DialogActions>
          </DialogContent>
        </BootstrapDialog>
      )} */}
    </>
  );
};

export default React.memo(Inbox);
