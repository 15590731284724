import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import { toastError, toastSuccess } from "helpers/toastHelper";
import { useEffect, useState, useId } from "react";
import { promptService } from "services";
import { Error } from "components/auth/Error";
import { Input } from "components/auth/Input";
import React from "react";
import "react-multi-email/dist/style.css";
import CircularProgress from "@mui/material/CircularProgress";

const BootstrapDialog = styled(Dialog)(({ theme }: any) => ({
  "& .MuiDialogContent-root": {
    padding: theme?.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme?.spacing(1),
  },
}));

function BootstrapDialogTitle(props: any) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const inputsConfig = {
  name: {
    name: "name",
    className: "form-control",
    type: "text",
    disabled: true,
  },
  content: {
    name: "content",
    placeholder: "Enter generic content",
    className: "form-control",
    type: "text",
    required: "Generic Prompt content is required",
    maxRows: 25,
    minRows: 8,
    multiline: true,
  },
};

export const UpdateGenericPrompt = ({
  open = false,
  handleClose = () => null,
  detail = null,
  getGenericPrompt = () => null,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!!detail) {
      setValue("name", detail?.promptType || "");
      setValue("content", detail?.prompt || "");
    }
  }, []);

  const onChange =
    (name: any) =>
    ({ target }: any) => {
      setValue(name, target.value);
    };

  const onSubmit = async ({ name, content }) => {
    try {
      setIsSubmitting(true);
      if (!!name && !!content && !!detail) {
        const payload = {
          prompt: content,
          genericPromptId: detail?.genericPromptId,
        };
        let response = null;
        response = await promptService.updateGenericPrompt(payload);
        if (response.data.success) {
          toastSuccess(response?.data?.message);
          getGenericPrompt();
        }
      }
    } catch (e) {
      toastError(e["response"]?.data?.message);
      setIsSubmitting(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderInput = (inputProps = {}) => {
    return (
      <Input
        {...{
          register,
          errors,
          watch,
          onChange,
          ...inputProps,
        }}
      />
    );
  };

  return (
    <>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle onClose={handleClose}>
          {"Update generic prompt details"}
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <form onSubmit={handleSubmit(onSubmit)} className="add-project">
            <div className="row">
              <div className="form-group col-lg-12">
                <label htmlFor="">Name</label>
                {renderInput(inputsConfig.name)}
              </div>
              {!!errors.title && <Error message={errors.name.message} />}
              <div className="form-group mt-4 col-lg-12">
                <label htmlFor="">Content</label>
                {renderInput(inputsConfig.content)}
              </div>
              {!!errors.prompt && <Error message={errors.content.message} />}

              <div className="form-group mt-4 col-lg-12">
                <DialogActions className="p-0">
                  <Button
                    disabled={isSubmitting}
                    className="save-btn mx-auto"
                    type="submit"
                  >
                    {isSubmitting ? (
                      <Box sx={{ display: "flex" }}>
                        <CircularProgress color="inherit" size={"1.5rem"} />
                      </Box>
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </DialogActions>
              </div>
            </div>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};
