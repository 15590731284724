const assets = {
  images: {
    logo: require("./images/userChatHistory.png"),
    loginLogo: require("./images/admin.png"),
    loginBg: require("./images/login-background.webp"),
    googleIcon: require("./images/google.png"),
    mainLogo: require("./images/admin.png"),
    defaultLogo: require("./images/logoo.png"),
    admin: require("./images/admin.png"),
    check: require("./images/check.png"),
    minus: require("./images/minus.png"),
    menuIcon: require("./images/option.png"),
    textIcon : require("./images/text.png"),
    loginImage: require("./images/login-image.png"),
    avatarImage: require("./images/Profile.png"),
    businessBox: require("./images/business-box.webp"),
    enterpriseBox: require("./images/enterprise-box.webp"),
    growthBox: require("./images/growth-box.webp"),
    freeTrial: require("./images/free-trial.png.bv.webp")
  },
};

export default assets;
