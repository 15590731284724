import { get } from "lodash";
import { Auth } from "types/Auth";
import BaseService from "./Base";
import apiUrls from "./ApiUrl";

class AuthService extends BaseService {
  constructor() {
    super(process.env.REACT_APP_API_BASE_URL);
  }

  async getOtp(data: any, params = {}) {
    try {
      const response = await this.post(apiUrls.sendOTP, data, params);
      return get(response, "data.data", {});
    } catch (error) {
      throw error;
    }
  }

  async otpSignIn(data: Auth.SignInParams, params = {}) {
    try {
      const response = await this.post(apiUrls.login, data, params);
      return get(response, "data.data", {});
    } catch (error) {
      throw error;
    }
  }

  async signIn(data: Auth.SignInParams): Promise<Auth.SignInResponse> {
    try {
      const response = await this.post(apiUrls.login, data);
      return get(response, "data", {});
    } catch (e: any) {
      throw e?.response?.data;
    }
  }

  async signInAsClient(data = {}): Promise<Auth.SignInResponse> {
    try {
      const response = await this.post(apiUrls.loginAs, data);
      return get(response, "data", {});
    } catch (e: any) {
      throw e?.response?.data;
    }
  }

  async sendOTPByHash(data = {}): Promise<Auth.SignInResponse> {
    try {
      const response = await this.post(apiUrls.sendOTP, data);
      return get(response, "data", {});
    } catch (e: any) {
      throw e?.response?.data;
    }
  }

  async verifyOTP(data = {}): Promise<Auth.SignInResponse> {
    try {
      const response = await this.post(apiUrls.verifyPassword, data);
      return get(response, "data", {});
    } catch (e: any) {
      throw e?.response?.data;
    }
  }
  async resetPassword(data = {}): Promise<any> {
    try {
      const response = await this.post(apiUrls.resetPassword, data);
      return get(response, "data", {});
    } catch (e: any) {
      throw e?.response?.data;
    }
  }

  async getMe(): Promise<any> {
    try {
      // const response = await this.get("/auth/v2/get-me")
      const response = await this.post("/v1/user/get-me", {});
      return get(response, "data.data", {});
    } catch (e: any) {
      throw e.response.data;
    }
  }

  async changePassword(data = {}): Promise<any> {
    try {
      const response = await this.post(apiUrls.changePassword, data);
      return get(response, "data", {});
    } catch (e: any) {
      throw e?.response?.data;
    }
  }

  async signUp(data: any): Promise<any> {
    try {
      const response = await this.post(apiUrls.register, data);
      return get(response, "data", {});
    } catch (e: any) {
      throw e?.response?.data;
    }
  }

  async googleSignIn(data: any): Promise<any> {
    try {
      const response = await this.post(apiUrls.googleSignIn, data);
      return get(response, "data", {});
    } catch (e: any) {
      throw e?.response?.data;
    }
  }

  async resendVerification(data: any): Promise<any> {
    try {
      const response = await this.post(apiUrls.resendVerification, data);
      return get(response, "data", {});
    } catch (e: any) {
      throw e?.response?.data;
    }
  }

  async linkValidation(data: any): Promise<any> {
    try {
      const response = await this.post(apiUrls.linkValidation, data);
      return get(response, "data", {});
    } catch (e: any) {
      throw e?.response?.data;
    }
  }

}

export default AuthService;
